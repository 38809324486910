import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';


export default function Logo() {



  return <RouterLink to="/">
    <Box
    component='img'
    sx={{
      height:50,
     // borderRadius:ß5,
      width:'auto',
     // resizeMode:"contain"
    }}
    alt='carousel2'
    src="/static/images/kantabetlogo1.png"></Box>
  
  </RouterLink>;
}

import { useState, useEffect, useContext } from 'react';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import {Helmet} from "react-helmet";
import StoreContext from "../../src/context/StoreContext";
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import axios from "axios";
import { format, addDays } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'



// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  InputAdornment,
  Box,
  LinearProgress,
  Select,
  FormControl,
  MenuItem

} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import { FixedSizeList } from 'react-window';





export default function PlaceStake() {
  const location = useLocation()
  
  const [data, setData] = useState({
    loading: false,
    fixtures:[],
    fixtureDate: format(new Date(), 'yyyy-MM-dd')
   
  });

 const navigate = useNavigate();
  const store = useContext(StoreContext)

  useState(()=>{
    getFixtures()
    console.log(location.state.leagueId)
console.log(location.state.season)
console.log(location.state.hasStats)
  },[])

  function getFixtures()
{

             

  setData((prevState) => ({
      ...prevState,
      loading:true
       }))


const leagueId = location.state.leagueId
const season = location.state.season

  axios
          .get(`https://v3.football.api-sports.io/fixtures?status=NS&season=${season}&date=${data.fixtureDate}&league=${leagueId}&timezone=Africa/Lagos`, {
            headers: {
              'x-rapidapi-host': 'v3.football.api-sports.io',
              'x-rapidapi-key': '58b756f8b397b21285e5f9e90d1f6b55'
            },
          })
          .then((response) => {
            setData((prevState) => ({
      ...prevState,
      loading:false,
      fixtures:response.data.response
       }))
         console.log(response.data) 
          
      }).catch((error) => {
        setData((prevState) => ({
      ...prevState,
      loading:false
       }))
        toast('An error occurred, please try again',{
        type:"error",
        position:"top-center",
        autoClose:5000,
        hideProgressBar:true,

      })
        console.log(error)
      });
}
 

  function getFixturesOnChange(val)
{

  const leagueId = location.state.leagueId
  const season = location.state.season          

  setData((prevState) => ({
      ...prevState,
      loading:true
       }))




  axios
          .get(`https://v3.football.api-sports.io/fixtures?status=NS&date=${val}&league=${leagueId}&season=${season}`, {
            headers: {
              'x-rapidapi-host': 'v3.football.api-sports.io',
              'x-rapidapi-key': '58b756f8b397b21285e5f9e90d1f6b55'
            },
          })
          .then((response) => {
            setData((prevState) => ({
      ...prevState,
      loading:false,
      fixtures:response.data.response
       }))
         console.log(response.data.response) 
          
      }).catch((error) => {
        setData((prevState) => ({
      ...prevState,
      loading:false
       }))
        toast('An error occurred, please try again',{
        type:"error",
        position:"top-center",
        autoClose:5000,
        hideProgressBar:true,

      })
        console.log(error)
      });
}
 



  return (
    <Page title="Fixtures">
      <Container>
        <Box mb={5}>
        <Box sx={{ display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
          <Typography variant="h4" gutterBottom>
            Fixtures
          </Typography>
           <FormControl variant="standard" size="small">
  
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
  value={data.fixtureDate}
    label='Date'
    onChange={(e)=>{
      setData((prevState) => ({
      ...prevState,
      fixtureDate:e.target.value
       }))
      getFixturesOnChange(e.target.value )
    }}
  >
    <MenuItem value={format(new Date(), 'yyyy-MM-dd')}>Today</MenuItem>

    <MenuItem value={format(addDays(new Date(), 1), 'yyyy-MM-dd')}>Tomorrow</MenuItem>
    <MenuItem value={format(addDays(new Date(), 2), 'yyyy-MM-dd')}>{format(addDays(new Date(), 2), 'yyyy-MM-dd')}</MenuItem>
    <MenuItem value={format(addDays(new Date(), 3), 'yyyy-MM-dd')}>{format(addDays(new Date(), 3), 'yyyy-MM-dd')}</MenuItem>
    <MenuItem value={format(addDays(new Date(), 4), 'yyyy-MM-dd')}>{format(addDays(new Date(), 4), 'yyyy-MM-dd')}</MenuItem>
    <MenuItem value={format(addDays(new Date(), 5), 'yyyy-MM-dd')}>{format(addDays(new Date(), 5), 'yyyy-MM-dd')}</MenuItem>
    <MenuItem value={format(addDays(new Date(), 6), 'yyyy-MM-dd')}>{format(addDays(new Date(), 6), 'yyyy-MM-dd')}</MenuItem>
  </Select>
</FormControl> 
</Box>
          {data.loading && (
             <LinearProgress />
           )}
           {data.fixtures.length === 0&&(
             <Typography sx={{marginTop:15, textAlign:'center'}}>No available games</Typography>
           )}

<Box>
 {data.fixtures.filter(({ fixture})=> new Date() < new Date(fixture.date)).map(
              ({ events, fixture, goals, league, score, teams, seasons  }, index) => {
                return (

                  <Box  sx={{width: '100%', backgroundColor:'whitesmoke', borderRadius:1, marginTop:1, padding:2 }}>
                   <Typography  sx={{fontSize:11, color:"grey", marginBottom:1}}>
                      {league.country +' - ' +league.name}
                    </Typography>
                  <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
<Box>
                  <Stack direction="row" spacing={1}>
                  <Box
                  component='img'
                  sx={{
    height:17,
    width: 17 
  }}
  alt='logo'
  src={teams.away.logo}></Box>
                    <Typography sx={{fontSize:13, fontWeight:'bold', top:-1.5}}>
                      {teams.away.name}
                    </Typography> 
                    </Stack>

                    
                    <Stack direction="row" spacing={1}>

                    <Box
                    component='img'
                  sx={{
    height:17,
    width: 17
  }}
  alt='logo'
  src={teams.home.logo}></Box>
                    <Typography sx={{fontSize:13, fontWeight:'bold', top:-1.5}}>
                      {teams.home.name}
                    </Typography>
                    </Stack>
                    </Box>
                    <Button onClick={()=>{
navigate('/dashboard/stake-market', { state: {
  id:fixture.id,
  gameStartTime:fixture.date,
  homeTeam:teams.home.name,
  awayTeam:teams.away.name,
  hasStats:location.state.hasStats,
  leagueId:league.id,
  date:format(new Date(fixture.date), 'yyyy-MM-dd'),
  season:league.season,
  league:league.name

 


} })}
} variant="contained">
        Stake
      </Button>
</Box>
<Box>
<Stack sx={{marginTop:1}} direction="row" spacing={1}>
<Typography sx={{fontSize:11, color:'grey'}}>{String(new Date(fixture.date).getHours()).padStart(2,0) + ":" + String(new Date(fixture.date).getMinutes()).padStart(2,0)}</Typography>
<Typography sx={{fontSize:11, color:'grey'}}>ID: {fixture.id}</Typography>
</Stack>
</Box>

                  </Box>
      
                )}
                )}
</Box>

    
        </Box>
       <ToastContainer />
       
      </Container>
      
    </Page>
  );
}












import * as Yup from 'yup';
import { useState, useContext } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

// material
import { Link, Stack, TextField, IconButton, InputAdornment, LinearProgress, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import StoreContext from "../../../../src/context/StoreContext";

// ----------------------------------------------------------------------

export default function LoginForm() {
  
  const navigate = useNavigate();
  const store = useContext(StoreContext)
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email:"",
    password:"",
    
   
  });
  const [data, setData] = useState({
    loading: false,
    
   
  });


  const beforeSubmit = () => {
    if(formData.email && formData.password){
      onSubmit()
    }else{
      toast('All fields are required',{
        type:"error",
        position:"top-center",
        autoClose:5000,
        hideProgressBar:true,

      })
    }
  }
 
  const onSubmit = () => {


    setData((prevState) => ({
      ...prevState,
      loading:true
       }))
       
    axios.post(`${store.baseApiURL}/login`, formData)
    .then(function (response) {
      if(response.data.user ==='user found'){
        console.log('hi')
       console.log(response.data.token)
      localStorage.setItem('user_token', response.data.token)
      const getimeValue = new Date().getTime() + (1000 * 60 * 60 * 1)
    localStorage.setItem('expiry_time', getimeValue)
      store.getUser()
      store.balance()
      window.location.href = '/dashboard/app'
    //  navigate('/dashboard/app', { replace: true });
      }else if(response.data.user ==='user not found'){
        toast('Invalid login credentials',{
          type:"error",
          position:"top-center",
          autoClose:5000,
          hideProgressBar:true,
  
        })
  
        
      }
      console.log(response);
      setData((prevState) => ({
        ...prevState,
        loading:false
         }))
    })
    .catch(function (error) {
      console.log(error);
      setData((prevState) => ({
        ...prevState,
        loading:false
         }))
      toast('An error occurred, please try again',{
        type:"error",
        position:"top-center",
        autoClose:5000,
        hideProgressBar:true,

      })
    });
  }


  

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            onChange={(e)=> setFormData((prevState) => ({
              ...prevState,
              email:e.target.value
               }))}
          
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            onChange={(e)=> setFormData((prevState) => ({
              ...prevState,
              password:e.target.value
               }))}
            
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          
          />
       

        <Stack direction="row" alignItems="center" justifyContent="space-between" >
         

          <Link  onClick={()=>{
           localStorage.removeItem('user_token')
   
           navigate('/forgot-password', { replace: true });
      }} variant="subtitle2" underline="hover">
            Forgot password?
          </Link>
        </Stack>
        {data.loading && (
             <LinearProgress />
           )}
        <Button onClick={beforeSubmit} fullWidth size="large" variant="contained" disabled={data.loading}>
          Login
        </Button>
        </Stack>
        <ToastContainer />
      
 </> );
}

import { useState, useContext } from 'react';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  InputAdornment,
  Box,
   LinearProgress,
   Link,
   IconButton
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import StoreContext from "../../src/context/StoreContext";
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import axios from "axios";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';




export default function Transactions() {
  const store = useContext(StoreContext)
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [data, setData] = useState({
    loading: false,
    showChangePassword:false,
    submitLoading:false
     
   
  });

  const [formData, setFormData] = useState({
   currentPassword:'',
   newPassword:'' 
   

  });

 

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleShowNewPassword = () => {
    setShowNewPassword((show) => !show);
  };

  const beforeSubmit = () =>{
    const upperCase = /[A-Z]/g;
    const lowerCase = /[a-z]/g;
    const number = /[0-9]/g;
    const passwordLength = formData.newPassword.length >= 8;
    if( formData.newPassword && formData.currentPassword ){
    
      if (
        formData.newPassword.match(upperCase) &&
        formData.newPassword.match(lowerCase) &&
        formData.newPassword.match(number) &&
        passwordLength
      ) {
      
      
          changePassword()
       
      }else{
        toast('Please enter a strong password',{
          type:"error",
          position:"top-center",
          autoClose:5000,
          hideProgressBar:true,
  
        })
      }
     
    }else{
      toast('All fields are required',{
        type:"error",
        position:"top-center",
        autoClose:5000,
        hideProgressBar:true,

      })
    }
  }


  function changePassword(){
    setData((prevState) => ({
      ...prevState,
      submitLoading:true,
     
       }))
    const token = localStorage.getItem('user_token')
    axios
    .put(`${store.baseApiURL}/change_password`,formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if(response.data === 'Password changed successfully'){
    
        toast('Password changed successfully',{
            type:"success",
            position:"top-center",
            autoClose:5000,
            hideProgressBar:true,
      
          })
      
    }else if(response.data === 'Incorrect current password'){
        toast('Incorrect current password',{
            type:"error",
            position:"top-center",
            autoClose:5000,
            hideProgressBar:true,
      
          })  
    }
      setData((prevState) => ({
        ...prevState,
        submitLoading:false,
       
       
       
         }))

    }).catch((error) => {
      setData((prevState) => ({
    ...prevState,
    submitLoading:false,
    
     }))
     
      toast('An error occurred, please try again',{
      type:"error",
      position:"top-center",
      autoClose:5000,
      hideProgressBar:true,

    })
      console.log(error)
    });

  } 


  return (
    <Page title="Settings">
      <Container>
      <Box px={{sm:2, lg:25}}>
        <Box mb={5}>
          <Typography variant="h4" gutterBottom>
          Settings
          </Typography>
         
        </Box>
       
{data.loading && (
             <LinearProgress />
           )}
<Stack spacing={3}>
<TextField  
            fullWidth
             type="text"
            label="First Name"
            value={store.userData.firstName}
           disabled={true} 
          
          />

<TextField  
            fullWidth
             type="text"
            label="Last Name"
            value={store.userData.lastName}
            disabled={true} 
          
          />

<TextField 
            fullWidth
             type="text"
            label="Email"
            value={store.userData.email}
            disabled={true}
          
          />
       
      

      <Link onClick={()=>{
           setData((prevState) => ({
            ...prevState,
            showChangePassword:true,
            
             }))
      }}>Change Password</Link>

<Link  onClick={()=>{
           localStorage.removeItem('user_token')
           window.location.href = '/login'
      }}>Log out</Link>

</Stack>
</Box>
   </Container>
<Dialog
        open={data.showChangePassword}
        fullWidth={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Change Password
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          
<Box mt={2}>
<Stack spacing={3}>
<TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Current Password"
            onChange={(e)=> setFormData((prevState) => ({
              ...prevState,
              currentPassword:e.target.value
               }))}
            
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          
          />

<TextField 
            fullWidth
            autoComplete="new-password"
            type={showNewPassword ? 'text' : 'password'}
            label="New Password"
            onChange={(e)=> setFormData((prevState) => ({
              ...prevState,
              newPassword:e.target.value
               }))}
            
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowNewPassword} edge="end">
                    <Iconify icon={showNewPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          
          />
          </Stack>
     {data.submitLoading && (
             <LinearProgress />
           )}
     <Box textAlign="center" mt={2} fontWeight='bold'>
        <Button fullWidth disabled={data.submitLoading}  onClick={()=>beforeSubmit()} variant="contained" size="large">
    Update
    </Button>
  
    </Box>
    
</Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          
          <Button  onClick={()=>{
            setData((prevState) => ({
              ...prevState,
              showChangePassword:false,
              }))
          }}>Close</Button>
          
         
        </DialogActions>
      </Dialog>

      <ToastContainer />
      
    </Page>
  );
}

import { useState, useEffect, useContext } from 'react';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import {Helmet} from "react-helmet";
import StoreContext from "../../src/context/StoreContext";
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import axios from "axios";
import { format, addDays } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'



// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  InputAdornment,
  Box,
  LinearProgress,
  Select,
  FormControl,
  MenuItem,
  ListItemButton,
  ListItemIcon,
  ListItemText

} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import { FixedSizeList } from 'react-window';





export default function Leagues() {
    const location = useLocation()
  
  const [data, setData] = useState({
    loading: false,
    leagues:[],
    searchLeague:''
   
   
  });

 const navigate = useNavigate();
  const store = useContext(StoreContext)

  useState(()=>{
      console.log(location.state.code)

    getLeagues()

  },[])

  function getLeagues()
{
    
        const countryName = location.state.name  
      

  setData((prevState) => ({
      ...prevState,
      loading:true,
      leagues:[]
       }))




  axios
          .get(`https://v3.football.api-sports.io/leagues?current=true&country=${countryName}`, {
            headers: {
              'x-rapidapi-host': 'v3.football.api-sports.io',
              'x-rapidapi-key': '58b756f8b397b21285e5f9e90d1f6b55'
            },
          })
          .then((response) => {
            console.log(response.data)
              // console.log(response.data.filter(({seasons})=> seasons[0].coverage.odds === true)) 
               
            setData((prevState) => ({
      ...prevState,
      loading:false,
      leagues:response.data.response
       }))
        
          
      }).catch((error) => {
        setData((prevState) => ({
      ...prevState,
      loading:false
       }))
        toast('An error occurred, please try again',{
        type:"error",
        position:"top-center",
        autoClose:5000,
        hideProgressBar:true,

      })
        console.log(error)
      });
}
 





  return (
    <Page title="Leagues">
      <Container>
        <Box mb={5}>
        <Box sx={{ display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
          <Typography variant="h4" gutterBottom>
           { location.state.name }
          </Typography>
          
</Box>
          {data.loading && (
             <LinearProgress />
           )}

<Box>
 {data.leagues.filter(({seasons})=> seasons[0].coverage.odds === true).map(
              ({ league, country, seasons }, index) => {
                return (

                 <>

                 <ListItemButton onClick={()=>{
navigate('/dashboard/place-stake', { state: {
  leagueId:league.id,
  season:seasons[0].year,
  hasStats:seasons[0].coverage.fixtures.statistics_fixtures
 
} })}
}>
                     <ListItemIcon>
                         {/**enter image here */}
                         <Box
                  component='img'
                  sx={{
    height:17,
    width: 17
  }}
  alt='logo'
  src={league.logo}></Box>
                     </ListItemIcon>
                     <ListItemText primary={league.name}/>
                 </ListItemButton>
                 
                 </>
      
                )}
                )}
</Box>

    
        </Box>
       <ToastContainer />
       
      </Container>
      
    </Page>
  );
}












import PropTypes from 'prop-types';
// material
import { observer } from "mobx-react-lite";
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Typography, Button } from '@mui/material';
// components
import Iconify from '../../components/Iconify';
import { useContext } from 'react';
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';
import StoreContext from "../../context/StoreContext";
import { useNavigate } from 'react-router-dom'

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

 DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
 };

 function DashboardNavbar({ onOpenSidebar }) {
   const store = useContext(StoreContext)
    const navigate = useNavigate();
  return (
    <RootStyle>
      <ToolbarStyle>
        <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
        <Box sx={{display:{xs:'block', sm:'block', lg:'none', xl:'none'}}}>
        <Box
    component='img'
    sx={{
      height:20,
     // borderRadius:ß5,
      width:'auto',
     // resizeMode:"contain"
    }}
    alt='carousel2'
    src="/static/images/kantabetlogo1.png"></Box>
    </Box>
       
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {localStorage.getItem('user_token') ? (<>
       <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>NGN {store.walletBalance.balance? parseFloat(store.walletBalance.balance)
                 .toFixed(2)
                 .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"):0.00}</Typography>
                 {/** 
          <NotificationsPopover /> <Searchbar />

*/}
          <AccountPopover />
         </> ):(<>
          <Button sx={{backgroundColor:'#f0bb0f', ':hover':{
            backgroundColor:'#f0bb0f', color: 'white'
          }}} onClick={()=>{
            
            navigate('/login', { replace: true })}
            } variant="contained">
                    Login
                  </Button>
                  <Button  onClick={()=>{
                     
                    navigate('/register', { replace: true })}
                    } variant="contained">
                            Register
                          </Button>
         </> )
          }
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}

 export default observer(DashboardNavbar);
import { useState, useEffect, useContext } from 'react';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import {Helmet} from "react-helmet";
import StoreContext from "../../src/context/StoreContext";
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import axios from "axios";
import { format, addDays } from 'date-fns'
import { useNavigate } from 'react-router-dom'



// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  InputAdornment,
  Box,
  LinearProgress,
  Select,
  FormControl,
  MenuItem,
  ListItemButton,
  ListItemIcon,
  ListItemText

} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import { FixedSizeList } from 'react-window';





export default function Countries() {

  
  const [data, setData] = useState({
    loading: false,
    countriesList:[],
    fixtureDate: format(new Date(), 'yyyy-MM-dd')
   
  });

 const navigate = useNavigate();
  const store = useContext(StoreContext)

  useState(()=>{
    getCountries()
    

  },[])


  var topCountries = "[England, France, Germany, Italy,  Scotland, Spain, Portugal, Belgium, Turkey, Netherlands]"
  var allCountries = "[Argentina, Armenia, Australia, Austria, Bolivia, Bosnia and Herzegovina, Brazil, Bulgaria, Canada, Chile, China, Colombia, Costa Rica, Crotia, Czech Republic, Denmark, Ecuador, Egypt, Estonia, Finland, Greece, Guatemala, Honduras, Hungary, Iceland, Ireland, Israel, Japan, Jordan, Mexico, Myanmar, Nicaragua, Northern Ireland, Norway, Panama, Paraguay, Peru, Poland, Qatar, Romania, Russia, Slovakia, Slovenia, South Africa, South Korea, Sweden, Thailand, Uruguay, USA, Venezuela, Wales, World]"





  function getCountries()
{

             

  setData((prevState) => ({
      ...prevState,
      loading:true
       }))




  axios
          .get(`https://v3.football.api-sports.io/countries`, {
            headers: {
              'x-rapidapi-host': 'v3.football.api-sports.io',
              'x-rapidapi-key': '58b756f8b397b21285e5f9e90d1f6b55'
            },
          })
          .then((response) => {
            setData((prevState) => ({
      ...prevState,
      loading:false,
      countriesList:response.data.response
       }))
         console.log(response.data.response) 
          
      }).catch((error) => {
        setData((prevState) => ({
      ...prevState,
      loading:false
       }))
        toast('An error occurred, please try again',{
        type:"error",
        position:"top-center",
        autoClose:5000,
        hideProgressBar:true,

      })
        console.log(error)
      });
}
 






  return (
    <Page title="Countries">
      <Container>
        <Box mb={5}>
        <Box sx={{ display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
          <Typography variant="h4" gutterBottom>
           Countries
          </Typography>
          
</Box>
          {data.loading && (
             <LinearProgress />
           )}

<Box>
<Typography>Top countries</Typography>
 {data.countriesList.filter(({name})=> topCountries.includes(name)).map(
              ({ name, code, flag }, index) => {
                return (

                 <>

                 <ListItemButton  onClick={()=>{
navigate('/dashboard/get-leagues', { state: {
 
  name:name
} })}
}>
                     <ListItemIcon>
                         {/**enter image here */}
                         <Box
                  component='img'
                  sx={{
    height:17,
    width: 17
  }}
  alt='logo'
  src={flag}></Box>
                     </ListItemIcon>
                     <ListItemText primary={name}/>
                 </ListItemButton>
                 
                 </>
      
                )}
                )}
</Box>

<Box>
<Typography>All countries</Typography> 
 {data.countriesList.filter(({name})=> allCountries.includes(name)).map(
              ({ name, code, flag }, index) => {
                return (

                 <>
 
                 <ListItemButton  onClick={()=>{
navigate('/dashboard/get-leagues', { state: {
  name:name,

} })}
}>
                     <ListItemIcon>
                         {/**enter image here */}
                         <Box
                  component='img'
                  sx={{
    height:17,
    width: 17
  }}
  alt='logo'
  src={flag}></Box>
                     </ListItemIcon>
                     <ListItemText primary={name}/>
                 </ListItemButton>
                 
                 </>
      
                )}
                )}
</Box>

    
        </Box>
       <ToastContainer />
       
      </Container>
      
    </Page>
  );
}












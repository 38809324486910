import { useState, useContext } from 'react';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  InputAdornment,
  Box,
  Divider,
  Link,
   LinearProgress,
   Chip
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import StoreContext from "../../src/context/StoreContext";
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import axios from "axios";
import { Icon } from '@iconify/react'
import { useNavigate } from 'react-router-dom'
import { format, add } from 'date-fns'



export default function Stakes() {
  const navigate = useNavigate();
  const store = useContext(StoreContext)
  const [data, setData] = useState({
    loading: false,
    stakes:[],
     
   
  });

  const [formData, setFormData] = useState({
    
   

  });

  useState(()=>{
    getStakes()

  },[])





  function getStakes(){
    setData((prevState) => ({
      ...prevState,
      loading:true,
     
       }))
    const token = localStorage.getItem('user_token')
    axios
    .get(`${store.baseApiURL}/get-user-stakes`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if(response.data){
        store.balance()
        console.log(response.data)
        setData((prevState) => ({
          ...prevState,
          stakes:response.data,
          
           }))
      
    }
      setData((prevState) => ({
        ...prevState,
        loading:false,
       
       
       
         }))

    }).catch((error) => {
      setData((prevState) => ({
    ...prevState,
    loading:false,
    
     }))
     
      toast('An error occurred, please try again',{
      type:"error",
      position:"top-center",
      autoClose:5000,
      hideProgressBar:true,

    })
      console.log(error)
    });

  } 


  return (
    <Page title="My Stakes">
      <Container>
      <Box px={{sm:2, lg:25}}>
        <Box >
          <Typography variant="h4" gutterBottom>
           My Stakes
          </Typography>
         
         
                  
        </Box>
       
        <Box >
        {data.loading && (
             <LinearProgress sx={{marginTop:5}}/>
           )}
{data.stakes.length === 0 && !data.loading && (
             <Typography mt={5} textAlign='center'>No Stakes</Typography>
           )}
           {data.stakes.map(({gameStartTime, league, homeTeam, awayTeam, stake, ref, isCountered, amount, gameStaked, teamStaked,id, fixtureId, playTime, counteredUserId, stakeResult}) =>{
            return(
              <>

            <Box mt={3}>
              <Typography fontSize='11px'>{ league }</Typography>
              <Typography fontSize='16px' fontWeight='bold'>{homeTeam} - {awayTeam}</Typography>
              
              
              <Typography fontSize='14px'>{gameStaked}({playTime ?playTime + ' ':''}{teamStaked} {stake}) - NGN {parseFloat(amount)
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} </Typography>
              <Typography fontSize='12px'>Status: {isCountered === 'false'? 'Not countered' : 'Countered'}</Typography>
              <Stack   direction="row"  spacing={1} >
              <Typography fontSize='12px'>ID: {fixtureId}</Typography>
              <Typography fontSize='12px'>{String(new Date(gameStartTime).getHours()).padStart(2,0) + ":" + String(new Date(gameStartTime).getMinutes()).padStart(2,0)} {format(new Date(gameStartTime), 'yyyy-MM-dd')}</Typography>
              </Stack>
               <Box sx={{display:'flex', justifyContent:'space-between', flexDirection:'row'}}>
              <Stack mt={2}  direction="row"  spacing={1} >
              <Typography fontSize='12px'>{stakeResult.charAt(0).toUpperCase() + stakeResult.slice(1)}: </Typography>
              <Icon icon="akar-icons:circle-fill" color={stakeResult === 'pending'?'lightgrey': stakeResult === 'win'?'green':stakeResult === 'lose'?'red':'lightgrey'}/>
              

             
               </Stack>
               {stakeResult !== 'reversed' && (
               <Stack mt={1}  direction="row"  spacing={1} >
               <Chip onClick={async()=>{
                 if(new Date() > add(new Date(gameStartTime), {minutes:105}) ){
                navigate('/dashboard/stake-details', { state: {
                  fixtureId:fixtureId,
                  gameStaked:gameStaked
               
              } })
              }else{
                toast('Game not started or running',{
                  type:"error",
                  position:"top-center",
                  autoClose:5000,
                  hideProgressBar:true,
            
                })
              }
               }} label='Result'/>
               {new Date(gameStartTime) > new Date() &&
               <Chip onClick={async()=>{
                 try{
                   await navigator.share({
                     title:'Game with me',
                     text:`Counter my bet and stand a chance to win - Betcode: ${fixtureId}`,
                     url:'https://kantabet.com'
                   })
                 } catch(err){
                   console.log(err)
                 }

               }} label='Share'/>
              }
               
               </Stack>
               )}
               </Box>
               <Divider sx={{marginTop:1}}/>
             
              
            </Box>
            
              </>
            )
           })

           }

</Box>
<ToastContainer />
       </Box>
      </Container>
    </Page>
  );









  







}



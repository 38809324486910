import { useState, useEffect, useContext } from 'react';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { Link as RouterLink, useParams } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import {Helmet} from "react-helmet";
import axios from "axios";
import numeral from "numeral";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"

import StoreContext from "../../src/context/StoreContext";

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  InputAdornment,
  LinearProgress,
  Box
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';




export default function FundAccount() {
  
const [formData, setFormData] = useState({
  amount:''
})
  const [data, setData] = useState({
    loading: false,
   
  
   
  });
   const store = useContext(StoreContext)
  


  
  
 
  



 function makePayment() {

  const squadInstance = new squad({
    onClose: () => console.log("Widget closed"),
    onLoad: () => console.log("Widget loaded successfully"),
    onSuccess: () => console.log(`Linked successfully`),
    key: "pk_6038f6a284a765b3be82e2e941413a8c2650ef11",
    payment_channels:  ['card', 'bank' , 'ussd','bank_transfer'],
    //Change key (test_pk_sample-public-key-1) to the key on your Squad Dashboard
    email: store.userData.email,
    amount: parseFloat(formData.amount.replace(/,/g, "")) * 100,
    //Enter amount in Naira or Dollar (Base value Kobo/cent already multiplied by 100) 
    currency_code: "NGN",
    metadata:{
      customerId: store.userData.id

    },
   
  });
  squadInstance.setup();
  squadInstance.open();

   
  }


  return (
    <Page title="Fund Account">
      <Container>
       <Box px={{sm:2, lg:25}}>
        <Box mb={5}>
          <Typography variant="h4" gutterBottom>
            Fund Account 
          </Typography>
          {data.loading && (
             <LinearProgress />
           )}
        </Box>
        <Box padding={2}>
        <TextField value={formData.amount} type="number" fullWidth onChange={(event)=>{
          
          
                setFormData((prevState) => ({
                  ...prevState,
                  amount: event.target.value
                }));
            
            }
            
            
        }   id="outlined-basic" label="Amount" variant="outlined" startAdornment={<InputAdornment position="start">NGN</InputAdornment>} /> 
<br /><br/>
<Box textAlign="center">
        <Button  onClick={()=>makePayment()} variant="contained" size="large">
      Fund Account
    </Button>
    </Box>
    <ToastContainer />


</Box>
   </Box>    
      </Container>
      <Helmet>
     <script src="https://checkout.squadco.com/widget/squad.min.js"></script>
       </Helmet>
    </Page>
  );
}

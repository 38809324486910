import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    
    Container,
    Box
  } from '@mui/material';
  // components
  import Page from '../components/Page';

export default function HowTo() {
  return (
   
       <Page title="How to">
      <Container>
       <Box px={{sm:2, lg:25}} mb={4}>
        <Box >
          <Typography mt={4} variant="h4" gutterBottom>
            How To Play?/FAQ
          </Typography>
          
        </Box>

        <div>

      <Accordion expanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>What is Kantabet?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Kantabet is a social gaming site that allows two users to bet against the outcome of a game.
             You can either stake or counter a game staked and stand the chance to be the
            winner and claim the amount staked.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>How to stake?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Sign up in less than 2 minutes, go through the list of popular games or games by countries/available leagues 
            and place a stake, add how much you want to place on the game and wait to be countered, you can also share your 
            game with friends and invite them to counter.
          </Typography>
        </AccordionDetails>
      </Accordion>
     
      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel31a-content"
          id="panel3a-header"
        >
          <Typography>How to counter?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Counter any game of your choice from the list of running games, the amount staked on the game will be deducted from 
            your account to counter the game.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>What can be staked on?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You can place a stake on multiple options of a game event i.e Number of Goals, Shots, Red cards, Yellows cards, Saves, Corner Kicks, Offsides, Ball Possession and many more.
          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel4a-header"
        >
          <Typography>What happens after the game ends?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            After the game ends, depending on the outcome, a winner is declared who gets the total staked amount. 
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel5a-header"
        >
          <Typography>How long before the winner is settled?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
           The winner is settled instantly after the game ends, the user's wallet balance will be updated with 
           the amount won.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel6a-header"
        >
          <Typography>How much can I stake?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          You can stake a minumum of NGN 500 and above.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel7a-header"
        >
          <Typography>What fees are charged?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
           A percentage of the total win is deducted as fee depending on how much is staked. Stakes between 
         NGN 500 to NGN 1,000 attracts 8% fee, stakes between NGN 1,001 to NGN 10,000 attracts 6% fee, Stakes above NGN 10,000
         attracts 5% fee.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel32a-content"
          id="panel7a-header"
        >
          <Typography>How do I withdraw my winnings?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          You can place a withdrawal request from your dashboard and it will be processed within 30 minutes.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel8a-header"
        >
          <Typography>How do I know when I win?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
           From the list of your stakes and your counters, you will see the result of every game you staked or countered.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel9a-header"
        >
          <Typography>What happens if my stake is not countered?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Your stake amount is automatically reversed to your wallet once the game starts.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel10a-header"
        >
          <Typography>What if the game is a tie?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          The amount staked and countered are reversed to the respective user's wallet accounts after the game.
          </Typography>
        </AccordionDetails>
      </Accordion>

    </div>
    
   </Box>    
      </Container>
      
    </Page>
  );
}

// component
import Iconify from '../../components/Iconify';
import Store from "../../../src/store/index";
// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

  var navConfigLogout= [
    
    {
      title: 'login',
      path: '/login',
      icon: getIcon('majesticons:login'),
    },
    {
      title: 'register',
      path: '/register',
      icon: getIcon('akar-icons:file'),
    },
   
    
  ];



export default navConfigLogout;

import { useState, useContext } from 'react';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
import {Helmet} from "react-helmet";
import Chart from 'react-apexcharts'

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  InputAdornment,
  Box,
   LinearProgress,
   Chip,
   Divider
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import StoreContext from "../../src/context/StoreContext";
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import axios from "axios";
import { useLocation } from 'react-router-dom'



export default function StakeDetails() {
  const store = useContext(StoreContext)
  const location = useLocation()
  const [data, setData] = useState({
    loading: false,
    stakeDetailsData:[],
    fixture:[]
     
   
  });

  const [formData, setFormData] = useState({
    
   

  });
  useState(()=>{
    getFixture()

  },[])
  function getFixture(){
    setData((prevState) => ({
     ...prevState,
     loading:true,
     fixture:[],
     options:{},
     series:[24, 56],
     labels:['A', 'B']
      }))
     
  const fixtureId = location.state.fixtureId

  axios
          .get(`https://v3.football.api-sports.io/fixtures?id=${fixtureId}`, {
            headers: {
              'x-rapidapi-host': 'v3.football.api-sports.io',
              'x-rapidapi-key': '58b756f8b397b21285e5f9e90d1f6b55'
            },
          })
          .then((response) => {
           
            setData((prevState) => ({
      ...prevState,
      loading:false,
      
    fixture:response.data.response,
      
       }))
         console.log(response.data) 
         
     
      }).catch((error) => {
        setData((prevState) => ({
      ...prevState,
      loading:false
       }))
        toast('An error occurred, please try again',{
        type:"error",
        position:"top-center",
        autoClose:5000,
        hideProgressBar:true,

      })
        console.log(error)
      });

  }


  return (
    <Page title="Stake Details">
      <Container>
      <Box px={{sm:2, lg:15}}>
        <Box mb={5}>
          <Typography variant="h4" gutterBottom>
          Stake Details
          </Typography>
         
        </Box>
        {data.loading && (
             <LinearProgress sx={{marginBottom:2}}/>
           )}

{data.fixture.map(
              ({ events, fixture, goals, league, score, teams, statistics }, index) => {
                return (<>
               <Box sx={{textAlign:'center'}}>
                <Chip label={String(new Date(fixture.date).getHours()).padStart(2,0) + ":" + String(new Date(fixture.date).getMinutes()).padStart(2,0)} />
                </Box>
           <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', padding:2}}>
<Box>
<Typography sx={{textAlign:'center', marginBottom:2}}>Home</Typography>
            
             <Box
                  component='img'
                  sx={{
    height:80,
    width: 80,
    marginLeft:'auto',
    marginRight:'auto',
   
  }}
  alt='logo'
  src={teams.home.logo}></Box>

             
             <Typography sx={{textAlign:'center', fontWeight:'bold',  marginTop:3, fontSize:14}}>{teams.home.name}</Typography>
             </Box>

<Typography fontSize='30px' color='grey' sx={{marginTop:6}}>{goals.home}:{goals.away}</Typography>

<Box>
<Typography sx={{textAlign:'center', marginBottom:2}}>Away</Typography>           
             <Box
                  component='img'
                  sx={{
                    alignSelf:'center',
    height:80,
    width: 80,
    marginLeft:'auto',
    marginRight:'auto',
 
  }}
  alt='logo'
  src={teams.away.logo}></Box>
  
          
             <Typography sx={{textAlign:'center', fontWeight:'bold', marginTop:3, fontSize:14}}>{teams.away.name}</Typography>
             </Box>




           </Box>
           <Stack direction="row" spacing={1} >
           <Typography sx={{fontSize:12}}>{league.country +' - ' +league.name}</Typography>
   
           <Typography sx={{fontSize:12}}>ID: {fixture.id}</Typography>
           </Stack>
           <Divider sx={{marginTop:1.5, marginBottom:1}}/>
           <Typography fontSize='14px'>Goals halftime</Typography>
           <Stack direction="row" spacing={1} >
           <Typography fontSize='12px'>Home: {score.halftime.home}</Typography>
           <Typography fontSize='12px'>Away: {score.halftime.away}</Typography>
           </Stack>
           <Divider sx={{marginTop:1.5, marginBottom:1}}/>
           <Typography fontSize='14px'>Goals fulltime</Typography>
           <Stack direction="row" spacing={1} >
           <Typography fontSize='12px'>Home: {score.fulltime.home}</Typography>
           <Typography fontSize='12px'>Away: {score.fulltime.away}</Typography>
           </Stack>
           <Divider sx={{marginTop:1.5, marginBottom:1}}/>
           {statistics.length !== 0  && (
           <Typography fontSize='14px'>Statistics Result</Typography>
           )}
           {statistics.length !== 0  && (
           <Box>
           {statistics.length !== 0 && statistics[0].statistics.filter(({type}) => type === location.state.gameStaked).map(({type, value}) =>{
            return(
<Box>
<Typography fontSize='12px'>{type} - (home): {value}</Typography>
</Box>
            )
          })}
 

          {statistics.length !== 0 && statistics[1].statistics.filter(({type}) => type === location.state.gameStaked).map(({type, value}) =>{
            return(
<Box>
<Typography fontSize='12px'>{type} - (away): {value}</Typography>
</Box>
            )
          })}
          
           <Divider sx={{marginTop:1.5}}/>
           </Box>
           )}
              </>  )}
              
)}
</Box>

      </Container>
    </Page>
  );
}

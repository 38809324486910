import { useState, useContext } from 'react';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  InputAdornment,
  Box,
   LinearProgress,
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import StoreContext from "../../src/context/StoreContext";
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import axios from "axios";




export default function ForgotPasword() {
  const store = useContext(StoreContext)
  const [showPassword, setShowPassword] = useState(false);
  const [data, setData] = useState({
    loading: false,
    showEmail:true,
    showResetFields:false
     
   
  });

  const [formData, setFormData] = useState({
      email:'',
      verificationCode:'',
      password:''
    
   

  });

  useState(()=>{
 

  },[])

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const sendEmailCode = async () => {
    const verifyCode = Math.floor(Math.random() * 899999 + 100000).toString();
   setFormData((prevState) => ({
      ...prevState,

      verificationCode: verifyCode,
    }));
  
    localStorage.setItem("verifyCode", verifyCode)
   
    
    setData((prevState) => ({
      ...prevState,

      loading: true,
    }));
    axios
      .post(store.baseApiURL + "/send_email_verification_code", formData)
      .then((response) => {
        
          
        if (response.data === "code sent") {
          setData((prevState) => ({
            ...prevState,
            loading:false,
            showResetFields: true,
          }));
 
        }
      })
      .catch((error) => {
        toast('An error occurred, please try again',{
            type:"error",
            position:"top-center",
            autoClose:5000,
            hideProgressBar:true,
    
          })
        setData((prevState) => ({
            ...prevState,
            loading:false
             }))
      });
  };


  const checkEmail =()=>{
    setData((prevState) => ({
      ...prevState,
      loading:true
       }))
    axios.post(`${store.baseApiURL}/check_email`, formData)
    .then(function (response) {
      setData((prevState) => ({
          ...prevState,
          loading:false
           }))
      if(response.data.msg === 'email exist'){
         sendEmailCode()
      }else if(response.data.msg === "email does not exist"){
        toast('Email does not exist',{
            type:"error",
            position:"top-center",
            autoClose:5000,
            hideProgressBar:true,
    
          })
      }
      
      
    })
    .catch(function (error) {
      console.log(error);
      setData((prevState) => ({
        ...prevState,
        loading:false
         }))
      toast('An error occurred, please try again',{
        type:"error",
        position:"top-center",
        autoClose:5000,
        hideProgressBar:true,

      })
    });

  }

  const beforeSubmit = () =>{
    const upperCase = /[A-Z]/g;
    const lowerCase = /[a-z]/g;
    const number = /[0-9]/g;
    const passwordLength = formData.password.length >= 8;
    if(formData.firstName && formData.lastName && formData.firstName && formData.email && formData.password ){
    
      if (
        formData.password.match(upperCase) &&
        formData.password.match(lowerCase) &&
        formData.password.match(number) &&
        passwordLength
      ) {
       
        forgotPassword()
     
      }else{
        toast('Please enter a strong password',{
          type:"error",
          position:"top-center",
          autoClose:5000,
          hideProgressBar:true,
  
        })
      }
     
    }else{
      toast('All fields are required',{
        type:"error",
        position:"top-center",
        autoClose:5000,
        hideProgressBar:true,

      })
    }
  }

  function forgotPassword(){
    setData((prevState) => ({
      ...prevState,
      loading:true,
     
       }))
    const token = localStorage.getItem('user_token')
    axios
    post(`${store.baseApiURL}/reset-password`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if(response.data === 'password reset'){
        
        toast('Password reset successful',{
            type:"success",
            position:"top-center",
            autoClose:5000,
            hideProgressBar:true,
      
          })
          navigate('/login', { replace: true })
    }
      setData((prevState) => ({
        ...prevState,
        loading:false,
       
       
       
         }))

    }).catch((error) => {
      setData((prevState) => ({
    ...prevState,
    loading:false,
    
     }))
     
      toast('An error occurred, please try again',{
      type:"error",
      position:"top-center",
      autoClose:5000,
      hideProgressBar:true,

    })
      console.log(error)
    });

  } 


  return (
    <Page title="Forgot Paswword">
      <Container>
       <Box px={{sm:2, lg:25}}>
        <Box mb={5}>
          <Typography sx={{marginTop:10}} variant="h4" gutterBottom>
            Forgot Paswword
          </Typography>
          {data.loading && (
             <LinearProgress />
           )}
        </Box>
        
{data.showEmail && (
    <>
        <TextField sx={{marginTop:10}}
            fullWidth
             type="email"
            label="Email address"
            onChange={(e)=> setFormData((prevState) => ({
              ...prevState,
              email:e.target.value
               }))}
          
          />

          <Button sx={{marginTop:3, marginBottom:20}} onClick={checkEmail} fullWidth size="large" variant="contained" disabled={data.loading}>
          Send Code
        </Button>
        </>  )}
          {data.loading.showResetFields && (
              <>
              
        <TextField
             sx={{marginTop:10}}
              fullWidth
              type='number'
              label="Enter Code"
              onChange={(e)=> setFormData((prevState) => ({
                ...prevState,
                verificationCode:e.target.value
                 }))}
            
            />
        <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            helperText="8 min characters, must include uppercase, lowercase and number"
            label="New Password"
            onChange={(e)=> setFormData((prevState) => ({
              ...prevState,
              password:e.target.value
               }))}
            
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          
          />
<Button sx={{marginTop:3,marginBottom:20}} onClick={()=>{
    if(formData.verifyCode === (localStorage.getItem('verifyCode'))){
    beforeSubmit()
    }else{
        toast('Code does not match',{
            type:"error",
            position:"top-center",
            autoClose:5000,
            hideProgressBar:true,
    
          })  
    }
    }} fullWidth size="large" variant="contained" disabled={data.loading}>
          Submit
        </Button>


          </>
          )}

     </Box>  
      </Container>
    </Page>
  );
}

import { useNavigate } from 'react-router-dom'
import { useState, useEffect, useContext } from 'react';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import {Helmet} from "react-helmet";
import StoreContext from "../../src/context/StoreContext";
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import axios from "axios";
import { format, addDays } from 'date-fns'
import { useLocation } from 'react-router-dom'


import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton'
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const style = {
  width: '100%',
  maxWidth: 360,
  bgcolor: 'background.paper',
};

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  InputAdornment,
  Box,
  LinearProgress,
  Select,
  FormControl,
  MenuItem,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Radio,
  ButtonGroup,
  Chip,
  Link

} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';

export default function stakeMarket() {
	const location = useLocation()
  const navigate = useNavigate();
  
  const [data, setData] = useState({
    loading: false,
     fixture:[],
     openModal:false,
    gameStaked:'',
    gameStakedCode:'',
    odds:[],
    openModalOdds:false,
    sumbitLoading:false
   // fixtureDate: format(new Date(), 'yyyy-MM-dd')
   
  });

  const [formData, setFormData] = useState({
    
   amount:'',
   teamStaked:'',
   league:location.state.league,
   stake:'',
   playTime:'',
   gameStaked:"",
   fixtureId:location.state.id,
   homeTeam:location.state.homeTeam,
   awayTeam:location.state.awayTeam,
   gameStartTime:location.state.gameStartTime,
   leagueId: location.state.leagueId,
   date: location.state.date,
   season: location.state.season

  });
 

  const store = useContext(StoreContext)

  useState(()=>{
    getFixture()
console.log(location.state.id)
  },[])
const relevantOdds = ["Match Winner", "Home/Away", "First Half Winner", "Second Half Winner", "Both Teams Score", "Exact Score", "To Win Either Half", "Results/Both Teams Score", "Results/Total Goals"]
  function getFixture(){
  	 setData((prevState) => ({
      ...prevState,
      loading:true,
      fixture:[]
       }))
      

const fixId = location.state.id

  axios
          .get(`https://v3.football.api-sports.io/fixtures?id=${fixId}`, {
            headers: {
              'x-rapidapi-host': 'v3.football.api-sports.io',
              'x-rapidapi-key': '58b756f8b397b21285e5f9e90d1f6b55'
            },
          })
          .then((response) => {
           
            setData((prevState) => ({
      ...prevState,
      loading:false,
      
    fixture:response.data.response,
      
       }))
         console.log(response.data) 
         
     
      }).catch((error) => {
        setData((prevState) => ({
      ...prevState,
      loading:false
       }))
        toast('An error occurred, please try again',{
        type:"error",
        position:"top-center",
        autoClose:5000,
        hideProgressBar:true,

      })
        console.log(error)
      });

  }


  function getOdds(){
    setData((prevState) => ({
     ...prevState,
     loading:true,
   
      }))


const fixId = location.state.id
const leagueId = location.state.leagueId
const date = location.state.date
const season = location.state.season


 axios
         .get(`https://v3.football.api-sports.io/odds?season=${season}&league=${leagueId}&date=${date}&bookmaker=1&fixture=${fixId}`, {
           headers: {
             'x-rapidapi-host': 'v3.football.api-sports.io',
             'x-rapidapi-key': '58b756f8b397b21285e5f9e90d1f6b55'
           },
         })
         .then((response) => {
          if(response.data.response.length !== 0){
           setData((prevState) => ({
     ...prevState,
     loading:false,
     openModalOdds:true,
     
   odds:response.data.length !== 0 ? response.data.response[0].bookmakers[0].bets : [],
   
     
      }))
    }else{
      setData((prevState) => ({
        ...prevState,
        loading:false,
        }))
      toast('Odds not available',{
        type:"error",
        position:"top-center",
        autoClose:5000,
        hideProgressBar:true,
 
      })
      
    }
         
        
    
     }).catch((error) => {
       setData((prevState) => ({
     ...prevState,
     loading:false
      }))
       toast('An error occurred, please try again',{
       type:"error",
       position:"top-center",
       autoClose:5000,
       hideProgressBar:true,

     })
       console.log(error)
     });

 }

  const placeStake = () =>{
    setData((prevState) => ({
      ...prevState,
      sumbitLoading:true,
     
       }))
    const token = localStorage.getItem('user_token')
    axios
    .post(`${store.baseApiURL}/place-stake`, formData,  {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if(response.data ==='stake placed'){
       store.balance() 
        setFormData((prevState) => ({
          ...prevState,
          stake:'',
          amount:'',
          teamStaked:'',
          playTime:''
           }))
      toast('Stake successful',{
        type:"success",
        position:"top-center",
        autoClose:5000,
        hideProgressBar:true,

      })
    }
      setData((prevState) => ({
        ...prevState,
        sumbitLoading:false,
        openModal:false
       
       
         }))

    }).catch((error) => {
      setData((prevState) => ({
    ...prevState,
    sumbitLoading:false,
    openModal:false
     }))
     setFormData((prevState) => ({
      ...prevState,
      stake:'',
      amount:'',
      teamStaked:'',
      playTime:''
       }))
      toast('An error occurred, please try again',{
      type:"error",
      position:"top-center",
      autoClose:5000,
      hideProgressBar:true,

    })
      console.log(error)
    });

  }
if(location.state.hasStats === true){
  var statistics = [
    {
      id:0,
      name:'Goals',
      shortName:'SOG'
     },
    {
     id:1,
     name:'Shots on Goal',
     shortName:'SOG'
    },

    {
      id:2,
      name:'Shots off Goal',
      shortName:'SOG'
     },
     {
      id:3,
      name:'Shots insidebox',
      shortName:'SIB'
     },
     {
      id:4,
      name:'Shots outsidebox',
      shortName:'SOG'
     },
     {
      id:5,
      name:'Total Shots',
      shortName:'TS'
     },
     {
      id:6,
      name:'Blocked Shots',
      shortName:'BS'
     },
     {
      id:7,
      name:'Fouls',
      shortName:'FL'
     },
     {
      id:8,
      name:'Corner Kicks',
      shortName:'CK'
     },
     {
      id:9,
      name:'Offsides',
      shortName:'OS'
     },
     {
      id:10,
      name:'Ball Possession',
      shortName:'BP'
     },
     {
      id:11,
      name:'Yellow Cards',
      shortName:'YC'
     },
     {
      id:12,
      name:'Red Card',
      shortName:'RC'
     },
     {
      id:13,
      name:'Goalkeeper Saves',
      shortName:'GS'
     },
     {
      id:14,
      name:'Total passess',
      shortName:'TP'
     },
     {
      id:15,
      name:'Passes accurate',
      shortName:'PA'
     },
     {
      id:16,
      name:'Passes %',
      shortName:'PP'
     },
    
  ]
  }else{
    var statistics = [
      {
        id:0,
        name:'Goals',
        shortName:'SOG'
       }

    ]
  }
    

  



 return (
    <Page title="Stake Market">
      <Container>
       <Box px={{sm:2, lg:15}}>
        <Box mb={3}>

          
          <Typography variant="h4" gutterBottom>
           Stake Market
          </Typography>
          
          
        </Box>
        {data.loading && (
             <LinearProgress sx={{marginBottom:2}}/>
           )}
{data.fixture.map(
              ({ events, fixture, goals, league, score, teams }, index) => {
                return (<>
               <Box sx={{textAlign:'center'}}>
                <Chip label={String(new Date(fixture.date).getHours()).padStart(2,0) + ":" + String(new Date(fixture.date).getMinutes()).padStart(2,0)} />
                </Box>
           <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', padding:2}}>
<Box sx={{width:'70%'}}>
<Typography sx={{textAlign:'center', marginBottom:2}}>Home</Typography>
            
             <Box
                  component='img'
                  sx={{
    height:80,
    width: 80,
    marginLeft:'auto',
    marginRight:'auto',
    
   
  }}
  alt='logo'
  src={teams.home.logo}></Box>

             
             <Typography sx={{textAlign:'center', fontWeight:'bold',  marginTop:3, fontSize:14, width:'90%'}}>{teams.home.name}</Typography>
             </Box>

<Typography fontSize='30px' color='grey' sx={{marginTop:6, width:'90%', textAlign:'center'}}>-:-</Typography>

<Box sx={{width:'70%'}}>
<Typography sx={{textAlign:'center', marginBottom:2}}>Away</Typography>           
             <Box
                  component='img'
                  sx={{
                    alignSelf:'center',
    height:80,
    width: 80,
    marginLeft:'auto',
    marginRight:'auto',
    
 
  }}
  alt='logo'
  src={teams.away.logo}></Box>
  
          
             <Typography sx={{textAlign:'center', fontWeight:'bold', marginTop:3, fontSize:14,  width:'90%'}}>{teams.away.name}</Typography>
             </Box>




           </Box>
           <Stack direction="row" spacing={1} >
           <Typography sx={{fontSize:12}}>{league.country +' - ' +league.name}</Typography>
   
           <Typography sx={{fontSize:12}}>ID: {fixture.id}</Typography>
           
           
           </Stack>
           <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', marginTop:2}}>
           <Stack direction="row" spacing={1.5} >
           <Link  component='button' variant='body2' onClick={()=>{getOdds()}}>Check odds</Link>
           <Link  component='button' variant='body2' onClick={()=> {
             navigate('/dashboard/all-stakes', { state: {
              fixtureId:fixture.id,
              
           
          } })

           }}>Counter</Link>
           </Stack>
             <Typography sx={{fontSize:12}}>{ location.state.date }</Typography>
             </Box>
            </>  )}
)}
 <Divider sx={{marginTop:1}}/>
{statistics.map(
              ({ id, name, shortName }, index) => {
                return (
       <List key={id} component="nav">

      <ListItem>
        <ListItemButton onClick={()=>{
          setData((prevState) => ({
            ...prevState,
            gameStaked:name,
            gameStakedCode:shortName,
            openModal:true
            
             }))

             setFormData((prevState) => ({
              ...prevState,
              gameStaked:name,
              
              
               }))

        }}>
        <ListItemText primary={name} />
        </ListItemButton>
      </ListItem>
      <Divider />



    </List>
                )}
)}

<Dialog
        open={data.openModal}
        
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {data.gameStaked}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          

 <FormControl >
      <FormLabel id="demo-row-radio-buttons-group-label" fontWeight='bold'>Team</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={formData.team}
        onChange={(e)=> {
          setFormData((prevState) => ({
            ...prevState,
            teamStaked:e.target.value
            }))
        }}
      >
        <FormControlLabel value="home" control={<Radio />} label="Home" />
        <FormControlLabel value="away" control={<Radio />} label="Away" />
       
        
      </RadioGroup>
    </FormControl>

    <FormControl >
      <FormLabel id="demo-row-radio-buttons-group-label" fontWeight='bold'>Stake</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={formData.stake}
        onChange={(e)=> {
          setFormData((prevState) => ({
            ...prevState,
            stake:e.target.value
            }))
        }}
      >
        <FormControlLabel value="win" control={<Radio />} label="Win" />
        <FormControlLabel value="lose" control={<Radio />} label="Lose" />

       
        
      </RadioGroup>
    </FormControl>
{data.gameStaked === 'Goals' && (
    <FormControl sx={{marginTop:3}}>
      <FormLabel id="demo-row-radio-buttons-group-label" fontWeight='bold'>Play Time</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={formData.playTime}
        onChange={(e)=> {
          setFormData((prevState) => ({
            ...prevState,
            playTime:e.target.value
            }))
        }}

      > 
        
        <FormControlLabel value="1H" control={<Radio />} label="First Half" />
         <FormControlLabel value="FT" control={<Radio />} label="Full Time" />  
      
        
        
      </RadioGroup>
    </FormControl>
)}
    <TextField sx={{marginTop:3}}
             value={formData.amount}
              fullWidth
              label="Amount"
              type='number'
              helperText="min: NGN 500"
              onChange={(e)=> {
                setFormData((prevState) => ({
                  ...prevState,
                  amount:e.target.value
                  }))
              }}
              
            />
            <Box sx={{ textAlign:"center"}}>
            {data.sumbitLoading && (
             <LinearProgress sx={{marginTop:2}}/>
           )}
  <Button isDisabled={data.sumbitLoading} onClick={()=>{
  
    if((data.gameStaked === 'Goals' && formData.stake && formData.amount && formData.teamStaked && formData.playTime) || (data.gameStaked !== 'Goals' && formData.stake && formData.amount )){
      if(store.walletBalance.balance >= parseInt(formData.amount)){
        if(parseInt(formData.amount) >= 500){
    placeStake()
        }else{
          toast('Stake minimum of NGN 500',{
            type:"error",
            position:"top-center",
            autoClose:5000,
            hideProgressBar:true,
      
          })
        }
      }else{
        toast('Insufficient balance',{
          type:"error",
          position:"top-center",
          autoClose:5000,
          hideProgressBar:true,
    
        })
      }   
    }else{
      toast('All fields are required',{
        type:"error",
        position:"top-center",
        autoClose:5000,
        hideProgressBar:true,
  
      })
    }
    
    }}size="large" sx={{marginTop:3}}  variant="contained">
        Submit
      </Button>
      </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          
          <Button  onClick={()=>{
            setData((prevState) => ({
              ...prevState,
              openModal:false,
              submitLoading:false,
              }))
              setFormData((prevState) => ({
                ...prevState,
                stake:'',
                amount:'',
                teamStaked:'',
                playTime:''
                 }))
          }}>Close</Button>
          
         
        </DialogActions>
      </Dialog>



<Dialog
        open={data.openModalOdds}
       
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Odds
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          
<Box>
  
      {
      data.odds.filter(({ name })=> relevantOdds.includes(name)).map(({name, values})=>{
        return(
          <>
          <Typography sx={{fontWeight:'bold'}}>{name}</Typography>
{values.map(({odd, value})=> {
  return(<>
  <Stack direction="row" spacing={3}>
 <Stack direction="row" spacing={2}>
  
  <Typography fontSize='12px' sx={{fontWeight:'bold'}}>{value}</Typography> 
  <Typography fontSize='12px' sx={{fontWeight:'bold'}}>-</Typography>
  <Typography fontSize='12px' sx={{fontWeight:'bold'}}>{odd}</Typography>
  </Stack>
  </Stack>
  
  </>)
})}
     <Divider sx={{ marginTop:3, marginBottom:2}}/>    
          </>
        )
      })
  }
    


</Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          
          <Button  onClick={()=>{
            setData((prevState) => ({
              ...prevState,
              openModalOdds:false,
              }))
          }}>Close</Button>
          
         
        </DialogActions>
      </Dialog>



      <ToastContainer />
      </Box>
      </Container>
    </Page>
    )
}
import { useState, useContext } from 'react';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  InputAdornment,
  Box,
   LinearProgress,
   Divider,
   Link,
   Chip
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import StoreContext from "../../src/context/StoreContext";
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import axios from "axios";
import { format, add } from 'date-fns'
import { Icon } from '@iconify/react'
import { useNavigate } from 'react-router-dom'





export default function Counters() {
  const store = useContext(StoreContext)
  const navigate = useNavigate();
  const [data, setData] = useState({
    loading: false,
    counters:[],
     
   
  });

  const [formData, setFormData] = useState({
    
   

  });

  useState(()=>{
    getCounters()

  },[])





  function getCounters(){
    setData((prevState) => ({
      ...prevState,
      loading:true,
     
       }))
    const token = localStorage.getItem('user_token')
    axios
    .get(`${store.baseApiURL}/get-user-counters`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if(response.data){
        console.log(response.data)
        setData((prevState) => ({
          ...prevState,
          counters:response.data,
          
           }))
      
    }
      setData((prevState) => ({
        ...prevState,
        loading:false,
       
       
       
         }))

    }).catch((error) => {
      setData((prevState) => ({
    ...prevState,
    loading:false,
    
     }))
     
      toast('An error occurred, please try again',{
      type:"error",
      position:"top-center",
      autoClose:5000,
      hideProgressBar:true,

    })
      console.log(error)
    });

  } 


  return (
    <Page title="Counters">
      <Container>
      <Box px={{sm:2, lg:25}}>
        <Box mb={5}>
          <Typography variant="h4" gutterBottom>
           My Counters
          </Typography>
         
        </Box>
        <Box >
        {data.loading && (
             <LinearProgress sx={{marginTop:5}}/>
           )}
{data.counters.length === 0 && !data.loading && (
             <Typography textAlign='center'>No counters</Typography>
           )}
           {data.counters.map(({gameStartTime, homeTeam, awayTeam, stake, ref, isCountered, amount,gameStaked, teamStaked,id, fixtureId, playTime, counteredUserId, counterResult}) =>{
            return(
              <>

            <Box mt={3}>
              
              <Typography fontSize='16px' fontWeight='bold'>{homeTeam} - {awayTeam}</Typography>
              <Typography fontSize='14px'>{gameStaked}({playTime ?playTime + ' ':''}{teamStaked} {stake}) - NGN {parseFloat(amount)
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} </Typography>
              <Typography fontSize='12px'>Status: {isCountered === 'false'? 'Not countered' : 'Countered'}</Typography>
              <Stack   direction="row"  spacing={1} >
              <Typography fontSize='12px'>ID: {fixtureId}</Typography>
              <Typography fontSize='12px'>{String(new Date(gameStartTime).getHours()).padStart(2,0) + ":" + String(new Date(gameStartTime).getMinutes()).padStart(2,0)} {format(new Date(gameStartTime), 'yyyy-MM-dd')}</Typography>
              </Stack>
              <Box sx={{display:'flex', justifyContent:'space-between', flexDirection:'row'}}>
              <Stack mt={1}  direction="row"  spacing={1} >
              <Typography fontSize='12px'>{counterResult.charAt(0).toUpperCase() + counterResult.slice(1)}: </Typography>
              <Icon icon="akar-icons:circle-fill" color={counterResult === 'pending'?'lightgrey': counterResult === 'win'?'green':counterResult === 'lose'?'red':'lightgrey'}/>
              

             
               </Stack>
              
               <Chip onClick={async()=>{
                 if(new Date() > add(new Date(gameStartTime), {minutes:105}) ){
                navigate('/dashboard/stake-details', { state: {
                  fixtureId:fixtureId,
                  gameStaked:gameStaked
               
              } })
              }else{
                toast('Game not started or running',{
                  type:"error",
                  position:"top-center",
                  autoClose:5000,
                  hideProgressBar:true,
            
                })
              }
               }} label='Result'/>
              
               </Box>
               <Divider sx={{marginTop:1}}/>
             
              
            </Box>
            
              </>
            )
           })

           }

</Box>
</Box>
<ToastContainer />
      </Container>
    </Page>
  );
}

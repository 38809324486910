import { useState, useEffect, useContext } from 'react';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import {Helmet} from "react-helmet";
import StoreContext from "../../src/context/StoreContext";
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import axios from "axios";
import { format, addDays } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'



// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  InputAdornment,
  Box,
  LinearProgress,
  Select,
  FormControl,
  MenuItem,
  Divider,
  Chip,
  Grid,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  Link

} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import { FixedSizeList } from 'react-window';
import Carousel from 'react-material-ui-carousel'

// sections


// ----------------------------------------------------------------------

export default function DashboardApp() {
  
  const navigate = useNavigate();
  const store = useContext(StoreContext)
  const [data, setData] = useState({
    loading: false,
    firstDayFixtures:[],
    secondDayFixtures:[],
    thirdDayFixtures:[],
    firstDayDate: format(new Date(), 'yyyy-MM-dd'),
    secondDayDate: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
    thirdDayDate: format(addDays(new Date(), 2), 'yyyy-MM-dd'),
    countriesList:[]
   
  });


  useEffect(()=> {
    firstDayFixturesFunc() 
    secondDayFixturesFunc()
    thirdDayFixturesFunc()
    getCountries()
  },[])

  var topLeagues = [2, 40, 39, 78, 79, 61, 135, 88, 94, 71, 253, 169, 5, 128, 10, 3, 848]
  

  function firstDayFixturesFunc(){
  
  axios
          .get(`https://v3.football.api-sports.io/fixtures?status=NS&date=${data.firstDayDate}&timezone=Africa/Lagos`, {
            headers: {
              'x-rapidapi-host': 'v3.football.api-sports.io',
              'x-rapidapi-key': '58b756f8b397b21285e5f9e90d1f6b55'
            },
          })
          .then((response) => {
            setData((prevState) => ({
      ...prevState,
 
      firstDayFixtures:response.data.response
       }))
      console.log(response.data.response)  
        
      })

  }


  function secondDayFixturesFunc(){
   

  axios
          .get(`https://v3.football.api-sports.io/fixtures?status=NS&date=${data.secondDayDate}&timezone=Africa/Lagos`, {
            headers: {
              'x-rapidapi-host': 'v3.football.api-sports.io',
              'x-rapidapi-key': '58b756f8b397b21285e5f9e90d1f6b55'
            },
          })
          .then((response) => {
            setData((prevState) => ({
      ...prevState,
   
      secondDayFixtures:response.data.response
       }))
       console.log(response.data.response) 
          
      })

  }

  function thirdDayFixturesFunc(){
  
  axios
          .get(`https://v3.football.api-sports.io/fixtures?status=NS&date=${data.thirdDayDate}&timezone=Africa/Lagos`, {
            headers: {
              'x-rapidapi-host': 'v3.football.api-sports.io',
              'x-rapidapi-key': '58b756f8b397b21285e5f9e90d1f6b55'
            },
          })
          .then((response) => {
            setData((prevState) => ({
      ...prevState,
    
      thirdDayFixtures:response.data.response
       }))
       console.log(response.data.response) 
          
      })

  }

  function getFixtures()
{  setData((prevState) => ({
  ...prevState,
  loading:true
   }))

  Promise.all([
  
  ]).then(()=>{
    setData((prevState) => ({
      ...prevState,
      loading:false
       }))

  }).catch((error) => {
    setData((prevState) => ({
  ...prevState,
  loading:false
   }))
    toast('An error occurred, please try again',{
    type:"error",
    position:"top-center",
    autoClose:5000,
    hideProgressBar:true,

  })
    console.log(error)
  });
  

  
}


  var topCountries = "[England, France, Germany, Italy,  Scotland, Spain, Portugal, Belgium, Turkey, Netherlands]"
 



  function getCountries()
{






  axios
          .get(`https://v3.football.api-sports.io/countries`, {
            headers: {
              'x-rapidapi-host': 'v3.football.api-sports.io',
              'x-rapidapi-key': '58b756f8b397b21285e5f9e90d1f6b55'
            },
          })
          .then((response) => {
            setData((prevState) => ({
      ...prevState,
     
      countriesList:response.data.response
       }))
         console.log(response.data.response) 
          
      }).catch((error) => {
        setData((prevState) => ({
      ...prevState,
      
       }))
        toast('An error occurred, please try again',{
        type:"error",
        position:"top-center",
        autoClose:5000,
        hideProgressBar:true,

      })
        console.log(error)
      });
}
 

 
  return (
    <>
    <Page title="Dashboard">
     <Grid container spacing={2}>
  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
  <Box>
  <Box sx={{display:{xs:'block', sm:'block', lg:'none', xl:'none'}}}>
    <Carousel  indicators={false} sx={{ marginTop:-3}}>
  <Box
    component='img'
    sx={{
      height:'200px',
   
      width:'100%',
      

      resizeMode:'contain' 
    }}
    alt='carousel1'
    src="/static/images/carousel2.png">
  </Box>
  <Box
    component='img'
    sx={{
      height:'200px',
      resizeMode:'contain',
      width:'100%'
    }}
    alt='carousel2'
    src="/static/images/carousel1.png">
  </Box>
  <Box
    component='img'
    sx={{
      height:'200px',
     resizeMode:'contain',
      width:'100%'
    }}
    alt='carousel2'
    src="/static/images/kanta3.png">
  </Box>
</Carousel>
</Box>
      <Container>
     
      <Stack mt={{xs:3, sm:3}}  direction="row" spacing={2} >
     
        <Chip onClick={()=>{
          if(localStorage.getItem('user_token')){
  navigate('/dashboard/get-countries', { replace: true })
          }else{
            navigate('/login', { replace: true })
          }
        }} label="All Countries"/>
<Chip onClick={()=>{
  if(localStorage.getItem('user_token')){
  navigate('/dashboard/all-stakes', { replace: true })
}else{
  navigate('/login', { replace: true })
}}
} label="Counter Stake"/>
        </Stack>
        <Divider sx={{marginTop:1}}/>


        {data.loading && (
             <LinearProgress />
           )}
           
      {/**today games */}    

<Box>
{localStorage.getItem('user_token') && (<>
<Typography sx={{marginTop:2, fontSize:12}}><span style={{fontWeight:'bold'}}>Total Bet Amount:</span> NGN {store.walletBalance.betBalance? parseFloat(store.walletBalance.betBalance)
                 .toFixed(2)
                 .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"):0.00}</Typography>


<Divider sx={{marginTop:1}}/>
</>)}
<Box sx={{marginTop:2, display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
<Typography sx={{ fontSize:12}}>Popular Games</Typography>
<Link  onClick={()=>{
          navigate('/how-to')
      }} fontSize='12px' >How to play?</Link>
</Box>

<Divider sx={{marginTop:1}}/>
{data.firstDayFixtures.filter(({league})=> topLeagues.includes(league.id)).length > 0 ?
  <Typography sx={{marginTop:2, fontWeight:'bold', fontSize:14}}>Today</Typography>
: null} 
 {data.firstDayFixtures.filter(({league, fixture})=> topLeagues.includes(league.id) && new Date() < new Date(fixture.date)).map(
              ({ events, fixture, goals, league, score, teams, seasons  }, index) => {
                return (
                  <>

                  <Box  sx={{width: '100%', backgroundColor:'whitesmoke', borderRadius:1, marginTop:1, padding:2 }}>
                   <Typography  sx={{fontSize:11, color:"grey", marginBottom:1}}>
                      {league.country +' - ' +league.name} 
                    </Typography>
                  <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
<Box>
                  <Stack direction="row" spacing={1}>
                  <Box
                  component='img'
                  sx={{
    height:17,
    width: 17
  }}
  alt='logo'
  src={teams.away.logo}></Box>
                    <Typography sx={{fontSize:13, fontWeight:'bold', top:-1.5}}>
                      {teams.away.name}
                    </Typography> 
                    </Stack>

                    
                    <Stack direction="row" spacing={1}>

                    <Box
                    component='img'
                  sx={{
    height:17,
    width: 17
  }}
  alt='logo'
  src={teams.home.logo}></Box>
                    <Typography sx={{fontSize:13, fontWeight:'bold', top:-1.5}}>
                      {teams.home.name}
                    </Typography>
                    </Stack>
                    </Box>
                    <Button onClick={()=>{
                      if(localStorage.getItem('user_token')){
navigate('/dashboard/stake-market', { state: {
  id:fixture.id,
  gameStartTime:fixture.date,
  homeTeam:teams.home.name,
  awayTeam:teams.away.name,
  hasStats:true,
  leagueId:league.id,
  date:format(new Date(fixture.date), 'yyyy-MM-dd'),
  season:league.season,
  league:league.name

 


} })
                    }else{
                      
                      navigate('/login', { replace: true })
                    }}
} variant="contained">
        Stake
      </Button>
</Box>
<Box>
<Stack sx={{marginTop:1}} direction="row" spacing={1}>
<Typography sx={{fontSize:11, color:'grey'}}>{String(new Date(fixture.date).getHours()).padStart(2,0) + ":" + String(new Date(fixture.date).getMinutes()).padStart(2,0)}</Typography>
<Typography sx={{fontSize:11, color:'grey'}}>ID: {fixture.id}</Typography>
</Stack>
</Box>

                  </Box>
      
             </>   )}
                )}
</Box>




{/** tomorrow games */}

<Box>
{data.secondDayFixtures.filter(({league})=> topLeagues.includes(league.id)).length > 0 ?
<Typography sx={{marginTop:3, fontWeight:'bold', fontSize:14}}>Tomorrow</Typography>
:null}
 {data.secondDayFixtures.filter(({league, fixture})=> topLeagues.includes(league.id) && new Date() < new Date(fixture.date)).map(
              ({ events, fixture, goals, league, score, teams, seasons  }, index) => {
                return (
                  <>

                  <Box  sx={{width: '100%', backgroundColor:'whitesmoke', borderRadius:1, marginTop:1, padding:2 }}>
                   <Typography  sx={{fontSize:11, color:"grey", marginBottom:1}}>
                      {league.country +' - ' +league.name} 
                    </Typography>
                  <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
<Box>
                  <Stack direction="row" spacing={1}>
                  <Box
                  component='img'
                  sx={{
    height:17,
    width: 17
  }}
  alt='logo'
  src={teams.away.logo}></Box>
                    <Typography sx={{fontSize:13, fontWeight:'bold', top:-1.5}}>
                      {teams.away.name}
                    </Typography> 
                    </Stack>

                    
                    <Stack direction="row" spacing={1}>

                    <Box
                    component='img'
                  sx={{
    height:17,
    width: 17
  }}
  alt='logo'
  src={teams.home.logo}></Box>
                    <Typography sx={{fontSize:13, fontWeight:'bold', top:-1.5}}>
                      {teams.home.name}
                    </Typography>
                    </Stack>
                    </Box>
                    <Button onClick={()=>{
                       if(localStorage.getItem('user_token')){
navigate('/dashboard/stake-market', { state: {
  id:fixture.id,
  gameStartTime:fixture.date,
  homeTeam:teams.home.name,
  awayTeam:teams.away.name,
  hasStats:true,
  leagueId:league.id,
  date:format(new Date(fixture.date), 'yyyy-MM-dd'),
  season:league.season

 


} })}else{
  
  navigate('/login', { replace: true })
}}
} variant="contained">
        Stake
      </Button>
</Box>
<Box>
<Stack sx={{marginTop:1}} direction="row" spacing={1}>
<Typography sx={{fontSize:11, color:'grey'}}>{String(new Date(fixture.date).getHours()).padStart(2,0) + ":" + String(new Date(fixture.date).getMinutes()).padStart(2,0)}</Typography>
<Typography sx={{fontSize:11, color:'grey'}}>ID: {fixture.id}</Typography>
</Stack>
</Box>

                  </Box>
      
              </>  )}
                )}
</Box>
    
   {/** third day fixtures   */}

   <Box>
     {data.thirdDayFixtures.filter(({league})=> topLeagues.includes(league.id)).length > 0 ? 
   <Typography sx={{marginTop:3, fontWeight:'bold', fontSize:14}}>{format(addDays(new Date(), 2), 'yyyy-MM-dd')}</Typography>
     :''}
   {data.thirdDayFixtures.filter(({league, fixture})=> topLeagues.includes(league.id) && new Date() < new Date(fixture.date)).map(
              ({ events, fixture, goals, league, score, teams, seasons  }, index) => {
                return (<>

                  <Box  sx={{width: '100%', backgroundColor:'whitesmoke', borderRadius:1, marginTop:1, padding:2 }}>
                   <Typography  sx={{fontSize:11, color:"grey", marginBottom:1}}>
                      {league.country +' - ' +league.name} 
                    </Typography>
                  <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
<Box>
                  <Stack direction="row" spacing={1}>
                  <Box
                  component='img'
                  sx={{
    height:17,
    width: 17
  }}
  alt='logo'
  src={teams.away.logo}></Box>
                    <Typography sx={{fontSize:13, fontWeight:'bold', top:-1.5}}>
                      {teams.away.name}
                    </Typography> 
                    </Stack>

                    
                    <Stack direction="row" spacing={1}>

                    <Box
                    component='img'
                  sx={{
    height:17,
    width: 17
  }}
  alt='logo'
  src={teams.home.logo}></Box>
                    <Typography sx={{fontSize:13, fontWeight:'bold', top:-1.5}}>
                      {teams.home.name}
                    </Typography>
                    </Stack>
                    </Box>
                    <Button onClick={()=>{
                      if(localStorage.getItem('user_token')){
navigate('/dashboard/stake-market', { state: {
  id:fixture.id,
  gameStartTime:fixture.date,
  homeTeam:teams.home.name,
  awayTeam:teams.away.name,
  hasStats:true, 
  leagueId:league.id,
  date:format(new Date(fixture.date), 'yyyy-MM-dd'),
  season:league.season

 


} })}else{
  
  navigate('/login', { replace: true })
}}
} variant="contained">
        Stake
      </Button>
</Box>
<Box>
<Stack sx={{marginTop:1}} direction="row" spacing={1}>
<Typography sx={{fontSize:11, color:'grey'}}>{String(new Date(fixture.date).getHours()).padStart(2,0) + ":" + String(new Date(fixture.date).getMinutes()).padStart(2,0)}</Typography>
<Typography sx={{fontSize:11, color:'grey'}}>ID: {fixture.id}</Typography>
</Stack>
</Box>

                  </Box>
      
              </>  )}
                )}
</Box>
        {/** 

        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

<Grid spacing={2}>
  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>


</Grid>
<Grid sx={{display:{xs:'none', sm:'none', lg:'block', xl:'block'}}} item lg={4} xl={4}>
<Box  sx={{marginLeft:7, marginTop:5}}>
 <Button size="large" variant="contained">Place stake</Button><br /><br />
<Button size="large" variant="outlined">Counter stake</Button>
</Box>
</Grid>
</Grid>
<Box textAlign="center" marginTop={5} marginBottom={5} sx={{display:{md:'none', lg:'none', xl:'none'}}}>

<Button onClick={()=>{
  navigate('/dashboard/get-countries', { replace: true })}
} sx={{display:'inline'}} size="large" variant="contained">Place stake</Button>
<Button sx={{display:'inline', marginLeft:3}} size="large" variant="outlined">Counter stake</Button>
</Box>


<iframe style={{border:"none", width:"100%", height:500, scrollbarWidth: "thin"}}  src="https://lucent-moonbeam-d93147.netlify.app" />
    

*/}


      </Container>
      </Box>
      
</Grid>
 <Grid item  lg={6} xl={6}>
  <Box>
  <Box mx={2} sx={{display:{xs:'none', sm:'none', lg:'block', xl:'block'}}}>
    <Carousel  indicators={false} >
  <Box
    component='img'
    sx={{
      height:'auto',
   
      width:'100%',
      

      resizeMode:'contain' 
    }}
    alt='carousel1'
    src="/static/images/carousel2.png">
  </Box>
  <Box
    component='img'
    sx={{
      height:'auto',
      resizeMode:'contain',
      width:'100%'
    }}
    alt='carousel2'
    src="/static/images/carousel1.png">
  </Box>
  <Box
    component='img'
    sx={{
      height:'auto',
     resizeMode:'contain',
      width:'100%'
    }}
    alt='carousel2'
    src="/static/images/kanta3.png">
  </Box>
</Carousel>
<Typography mt={2}>Top countries</Typography>
 {data.countriesList.filter(({name})=> topCountries.includes(name)).map(
              ({ name, code, flag }, index) => {
                return (

                 <>

                 <ListItemButton  onClick={()=>{
     if(localStorage.getItem('user_token')){                
navigate('/dashboard/get-leagues', { state: {
 
  name:name
} })

     }else{
  
      navigate('/login', { replace: true })
    }


}
}>
                     <ListItemIcon>
                         {/**enter image here */}
                         <Box
                  component='img'
                  sx={{
    height:17,
    width: 17
  }}
  alt='logo'
  src={flag}></Box>
                     </ListItemIcon>
                     <ListItemText primary={name}/>
                 </ListItemButton>
                 
                 </>
      
                )}
                )}


</Box>
</Box>
</Grid>
  </Grid>
    </Page>
   
    </>
  );
}

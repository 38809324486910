import {
  
    Box,
    LinearProgress,
    Container
     
  } from '@mui/material';

export default function AppLoading(){
  return (<>
  <Container>
  <Box px={{sm:2, lg:25}}>
  <Box sx={{marginTop:30, marginBottom:35, textAlign:'center'}}>
  

    <LinearProgress sx={{marginTop:5, backgroundColor:'#483666'}}/>
    </Box>
    </Box>
   </Container>
 </> )
}
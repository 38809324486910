import { useState, useContext } from 'react';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"
import axios from "axios";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import StoreContext from "../../src/context/StoreContext";

// material
import {
  Card,
  Table,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  InputAdornment,
  Box,
  FormControl,
  InputLabel,
  Select,
  Stack,
  MenuItem,
  LinearProgress,
  Grid
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';




export default function Withdraw() {
  const [amount, setAmount] = useState()
  const [formData, setFormData] = useState({
    amount:'',
    accountBank:'',
    accountNumber:'',
    accountName:'',
    bankName:''
  })
    const [data, setData] = useState({
      loading: false,
      banksLoading:false,
      accountNameLoading:false,
      banksData:[],
      openAccountName:false
     
    
     
    });
    const store = useContext(StoreContext)
    useState(()=>{
      getBanks()
    },[])
   
   

 async function getBanks() {
    setData((prevState) => ({
      ...prevState,
      banksLoading:true,
     
       }))
  
    axios
    .get(`https://maylancer.org/api/nuban/banklist.php`)
    .then((response) => {
      if(response
        ){
        console.log(response.data)
        setData((prevState) => ({
          ...prevState,
          banksData:response.data,
          
           }))
           
    }
    setData((prevState) => ({
      ...prevState,
      banksLoading:false,
     
       }))

    }).catch((error) => {
      setData((prevState) => ({
        ...prevState,
        banksLoading:false,
       
         }))
     
      toast('An error occurred, please try again',{
      type:"error",
      position:"top-center",
      autoClose:5000,
      hideProgressBar:true,

    })
      console.log(error)
    });


  }

  
  const withdrawFund = () =>{
    setData((prevState) => ({
      ...prevState,
      loading:true,
     
       }))
    const token = localStorage.getItem('user_token')
    axios
    .post(`${store.baseApiURL}/withdraw-funds`, formData,  {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if(response.data ==='request successful'){
        setData((prevState) => ({
          ...prevState,
          loading:false,
          openAccountName:false
         
           }))
           
           store.balance() 
      toast('Withdrawal request successful',{
        type:"success",
        position:"top-center",
        autoClose:5000,
        hideProgressBar:true,

      })
    }
      

    }).catch((error) => {
      setData((prevState) => ({
        ...prevState,
        loading:false,
       
         }))
    
      toast('An error occurred, please try again',{
      type:"error",
      position:"top-center",
      autoClose:5000,
      hideProgressBar:true,

    })
      console.log(error)
    });

  }

async function getAccountName(){
    setData((prevState) => ({
      ...prevState,
      accountNameLoading:true,
     
       }))
      const accountNumber = formData.accountNumber
      const accountBank = formData.accountBank 
    const token = localStorage.getItem('user_token')
   await axios
    .get(`${store.baseApiURL}/get-account-name/${accountNumber}/${accountBank}`,  {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if(response.data.account_name){
        setData((prevState) => ({
          ...prevState,
          accountNameLoading:false,
          openAccountName:true
         
           }))
           setFormData((prevState) => ({
            ...prevState,
            accountName:response.data.account_name,
            accountNumber:response.data.account_number,
            bankName: response.data.Bank_name
           
             }))
       
     
    }else{
      toast('Name verification failed',{
        type:"error",
        position:"top-center",
        autoClose:5000,
        hideProgressBar:true,
  
      })
      setData((prevState) => ({
        ...prevState,
        accountNameLoading:false,
     
       
         }))
    }
      

    }).catch((error) => {
      setData((prevState) => ({
        ...prevState,
        accountNameLoading:false,
       
         }))
    
      toast('An error occurred, please try again',{
      type:"error",
      position:"top-center",
      autoClose:5000,
      hideProgressBar:true,

    })
      console.log(error)
    });

  }


  return (
    <Page title="Withdraw">
      <Container >
      <Box px={{sm:2, lg:25}}>
        <Box mb={2}>
          <Typography variant="h4" gutterBottom>
           Withdraw
          </Typography>
         
        </Box>
       
        
        
        <Box >
        
           {data.banksLoading && (
             <LinearProgress />
           )}
           {data.accountNameLoading && (
             <LinearProgress />
           )}
  <Stack mt={2} spacing={2}> 
  <TextField type='number'
onChange={(e)=> 
  {
    
  setFormData((prevState) => ({
  ...prevState,
  amount:e.target.value
   }))}}

fullWidth value={formData.amount} id="outlined-basic" label="Amount" variant="outlined" startAdornment={<InputAdornment position="start">NGN</InputAdornment>}/>

  <FormControl fullWidth>
  <InputLabel id="demo-simple-select-label">Banks</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={formData.accountBank}
    label="Age"
    onChange={(e)=> {
      setFormData((prevState) => ({
        ...prevState,
        accountBank:e.target.value
         }))
      if(e.target.value.length === 10){
      getAccountName()}}}
  >
    {data.banksData.map(
                        ({id,  code, name}, index) => {
                          return (
    <MenuItem key={id} value={code}>{name}</MenuItem>
                          )}
    )}
    
  </Select>
</FormControl>    
<TextField 
type='number'
helperText="Fee: NGN 25"
onChange={(e)=> 
  {
   
  setFormData((prevState) => ({
  ...prevState,
  accountNumber:e.target.value
   }))}}
fullWidth value={formData.accountNumber} id="outlined-basic" label="Account Number" variant="outlined" min='1' max='10'/>

<Box textAlign="center">
        <Button fullWidth disabled={data.loading}  onClick={()=>{
          if(formData.amount && formData.accountNumber && formData.accountBank){
            if(store.walletBalance.balance >= (parseInt(formData.amount) + 25)){
              if(parseInt(formData.amount) >= 1000){
          getAccountName()
              }else{
                toast('Can only withdraw a minimum of NGN 1,000',{
                  type:"error",
                  position:"top-center",
                  autoClose:5000,
                  hideProgressBar:true,
            
                })
              }
          }else{
            toast('Insufficient balance',{
              type:"error",
              position:"top-center",
              autoClose:5000,
              hideProgressBar:true,
        
            })
          }
          }else{
            toast('All fields are required',{
              type:"error",
              position:"top-center",
              autoClose:5000,
              hideProgressBar:true,
        
            })
          }
          
          }} variant="contained" size="large" disabled={data.accountNameLoading}>
      Continue
    </Button>
    </Box>

    <Typography sx={{fontSize:13, color: 'grey', textAlign:'center', marginTop:3}}>Payments are processed within 30 minutes</Typography>
    
</Stack> 

</Box>

<Dialog
        open={data.openAccountName}
        fullWidth={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Account Name
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          
<Box>
  
     <Typography sx={{fontSize:17, textAlign:'center'}}>{formData.accountName}</Typography>
     <Typography sx={{fontSize:15, textAlign:'center'}}>{formData.accountNumber}</Typography>
     {data.loading && (
             <LinearProgress />
           )}
     <Box textAlign="center" mt={2} fontWeight='bold'>
        <Button fullWidth disabled={data.loading}  onClick={()=>withdrawFund()} variant="contained" size="large">
      Request Withdrawal
    </Button>
    </Box>

</Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          
          <Button  onClick={()=>{
            setData((prevState) => ({
              ...prevState,
              openAccountName:false,
              }))
          }}>Close</Button>
          
         
        </DialogActions>
      </Dialog>

<ToastContainer />
</Box>
      </Container>
    </Page>
  );
}

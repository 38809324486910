import * as React from "react";
import { runInAction, makeAutoObservable } from "mobx";
import axios from "axios";
import numeral from "numeral";




class Store {
    baseApiURL = "https://api.kantabet.com" //"https://5db1-197-210-227-205.ngrok.io";
    userData = []
    walletBalance = []
    userToken = ""


constructor() {
    makeAutoObservable(this);
  }
  async getUserToken() {
    
    const token = localStorage.getItem('user_token')
   // console.log(token)
    runInAction(() => {
      this.userToken = token;
     
    });
  }

  async balance() {
     
     const token = localStorage.getItem('user_token')
    if (token !== null) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${this.baseApiURL}/balance`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            console.log(response.data);
            if (response.data) {
              runInAction(() => {
                this.walletBalance = response.data;
              });
            }

            resolve();
          });
      }).catch((error) => console.log(error)); // log error message
    }
  }

  async getUser() {
    const token = localStorage.getItem('user_token')

    if (token) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${this.baseApiURL}/get_user`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            console.log(response.data);
            if (response.data) {
              runInAction(() => {
                this.userData = response.data.user;
              //  console.log(response.data.user)
              });
              //  resolve(response)
            }
            resolve(response);
          });
      }).catch((error) => {});
    }
  }


}
  export default new Store();
   


import React from "react";
import {
    AppBar,
    Toolbar,
    Typography,
    Grid,
    Link,
    Stack,
    Divider,
    Container,
    Box
} from "@mui/material";
import { useNavigate } from 'react-router-dom'



const Footer = () => {
    const navigate = useNavigate();
    return (
        <>
        <Box sx={{bottom:0, position:'relative', backgroundColor:"#483666"}}> 
        <Divider sx={{marginBottom:3}} />
        <Container>
        <Stack spacing={3}>
        <Typography sx={{textAlign:'center', color:'white'}} >
                      You must be age 18+ to play or register on Kantabet
                    </Typography>
                    <Typography sx={{textAlign:'center', color:'lightgray'}}>
                       Payment methods: VISA | VERVE | MASTERCARD | BANK TRANSFER | USSD
                    </Typography>
                    <Box textAlign='center'>
                    <Link mt={1} mb={1} fontSize='15px' onClick={()=>{
          navigate('/how-to')
      }} fontSize='15px' mb={1}>How to play?</Link>
      </Box>
        <Stack direction="row" justifyContent='center' spacing={1}>
                <Link fontSize='12px' onClick={()=>{
          navigate('/terms')
      }} fontSize='12px' mb={1}>Terms of service</Link>
 <Typography fontSize='12px' color='white'> and </Typography>
<Link fontSize='12px' onClick={()=>{
          navigate('/privacy')
      }}>Privacy policy</Link>
                </Stack>
                </Stack>
                </Container>
        <AppBar position="static" elevation={0} component="footer" sx={{background:'#483654'}}>
            <Toolbar style={{ justifyContent: "center" }}>
                <Typography color='white' variant="caption">©2022. All rights reserved</Typography>
                {/** 
                <Stack direction="row"  spacing={1}>
                <Link onClick={()=>{
          navigate('/terms')
      }}>Terms of service</Link>
 <Typography> | </Typography>
<Link onClick={()=>{
          navigate('/privacy')
      }}>Privacy policy</Link>
                </Stack>
*/}
            </Toolbar>
        </AppBar>
        </Box> 
        </>
    )
}

export default Footer;
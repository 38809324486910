import { useState, useContext } from 'react';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  InputAdornment,
  Box,
   LinearProgress,
   Chip,
   Divider
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import StoreContext from "../../src/context/StoreContext";
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import axios from "axios";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Icon } from '@iconify/react'
import { format, addDays } from 'date-fns'




export default function AllStakes() {
  const location = useLocation()
  const navigate = useNavigate();
  const store = useContext(StoreContext)
  const [data, setData] = useState({
    loading: false,
    allStakes:[],
    showConfirmCounter:false,
    counterAmount:'',
    submitLoading:false,
    openModalOdds:false,
    odds:[]
     
   
  });

  const [formData, setFormData] = useState({
    stakeId:'',
    amount:'',
    searchId:location.state?.fixtureId ? location.state.fixtureId : ''


   

  });

  useState(()=>{
    getAllStakes()

  },[])
  const relevantOdds = ["Match Winner", "Home/Away", "First Half Winner", "Second Half Winner", "Both Teams Score", "Exact Score", "To Win Either Half", "Results/Both Teams Score", "Results/Total Goals"]

function searchResults(){
 
  if(formData.searchId){
    const regexp = new RegExp(formData.searchId, "i");
 return data.allStakes.filter(({fixtureId, ref}) => regexp.test(fixtureId))
  }else{
return data.allStakes
  }

}

function getOdds(val){
  setData((prevState) => ({
   ...prevState,
   loading:true,
   openModalOdds:true
    }))

    
axios
       .get(`https://v3.football.api-sports.io/odds?season=${val[3]}&league=${val[1]}&date=${val[2]}&bookmaker=1&fixture=${val[0]}`, {
         headers: {
           'x-rapidapi-host': 'v3.football.api-sports.io',
           'x-rapidapi-key': '58b756f8b397b21285e5f9e90d1f6b55'
         },
       })
       .then((response) => {
        if(response.data.response.length !== 0){
         setData((prevState) => ({
   ...prevState,
   loading:false,
   
 odds:response.data.response[0].bookmakers[0].bets,
 
   
    }))
  }else{
    setData((prevState) => ({
      ...prevState,
      loading:false,
     
    
      
       }))
    toast('Odds not available',{
      type:"error",
      position:"top-center",
      autoClose:5000,
      hideProgressBar:true,

    })
  }
      
  
   }).catch((error) => {
     setData((prevState) => ({
   ...prevState,
   loading:false
    }))
     toast('An error occurred, please try again',{
     type:"error",
     position:"top-center",
     autoClose:5000,
     hideProgressBar:true,

   })
     console.log(error)
   });

}


  function getAllStakes(){
    setData((prevState) => ({
      ...prevState,
      loading:true,
     
       }))
    const token = localStorage.getItem('user_token')
    axios
    .get(`${store.baseApiURL}/get-stakes`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if(response.data){
        console.log(response.data)
        setData((prevState) => ({
          ...prevState,
          allStakes:response.data,
          
           }))
      
    }
      setData((prevState) => ({
        ...prevState,
        loading:false,
       
       
       
         }))

    }).catch((error) => {
      setData((prevState) => ({
    ...prevState,
    loading:false,
    
     }))
     
      toast('An error occurred, please try again',{
      type:"error",
      position:"top-center",
      autoClose:5000,
      hideProgressBar:true,

    })
      console.log(error)
    });

  } 


  function counterStake() {
    setData((prevState) => ({
      ...prevState,
      submitLoading:true,
     
       }))
    const token = localStorage.getItem('user_token')
    axios
    .put(`${store.baseApiURL}/counter-stake`, formData,  {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if(response.data ==='stake countered'){
       store.balance() 
       getAllStakes()
      toast('Counter successful',{
        type:"success",
        position:"top-center",
        autoClose:5000,
        hideProgressBar:true,

      })
    }
      setData((prevState) => ({
        ...prevState,
        submitLoading:false,
        showConfirmCounter:false
       
       
         }))

    }).catch((error) => {
      setData((prevState) => ({
    ...prevState,
    sumbitLoading:false,
    showConfirmCounter:false
     }))
     
      toast('An error occurred, please try again',{
      type:"error",
      position:"top-center",
      autoClose:5000,
      hideProgressBar:true,

    })
      console.log(error)
    });

  }

  return (
    <Page title="AllStakes">
      <Container>
        <Box px={{sm:2, lg:25}}>
        <Box mb={5}>
          <Typography variant="h4" gutterBottom>
          Running Stakes
          </Typography>
         
        </Box>
        <Box >
        <TextField
        mb={2}
            fullWidth
            autoComplete=""
            type="number"
            label="Stake Id"
           value={formData.searchId}
            onChange={(e)=> setFormData((prevState) => ({
              ...prevState,
              searchId:e.target.value
               }))}
          
          />
        {data.loading && (
             <LinearProgress sx={{marginTop:5}}/>
           )}
{searchResults().length === 0 && !data.loading && (
             <Typography mt={3} textAlign='center'>No Stakes</Typography>
           )}
           {searchResults().filter(({gameStartTime, isCountered})=> new Date(gameStartTime) > new Date()  && isCountered === 'false').map(({gameStartTime, leagueId, date, season, league, homeTeam, awayTeam, stake, ref, isCountered, amount,gameStaked, teamStaked,id, fixtureId, playTime, counteredUserId, gameStatus, userId}) =>{
            return(
              <>

            <Box mt={3}>
            <Typography fontSize='11px'>{ league }</Typography>
              <Typography fontSize='16px' fontWeight='bold'>{homeTeam} - {awayTeam}</Typography>
              
              <Typography fontSize='14px'>{gameStaked}({playTime ?playTime + ' ':''}{teamStaked} {stake}) - NGN {parseFloat(amount)
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} </Typography>
              <Stack   direction="row"  spacing={1} >
              <Typography fontSize='12px'>ID: {fixtureId}</Typography>
              <Typography fontSize='12px'>{String(new Date(gameStartTime).getHours()).padStart(2,0) + ":" + String(new Date(gameStartTime).getMinutes()).padStart(2,0)} {format(new Date(gameStartTime), 'yyyy-MM-dd')}</Typography>
              </Stack>
              <Box sx={{display:'flex', justifyContent:'space-between', flexDirection:'row'}}>
              
               <Stack mt={1}  direction="row"  spacing={1} >
               
              
               <Chip onClick={async()=>{
                 if(userId !== store.userData.id){
                  setData((prevState) => ({
                    ...prevState,
                    showConfirmCounter:true,
                    
                    
                     }))
                     setFormData((prevState) => ({
                      ...prevState,
                      stakeId:ref,
                      amount:amount
                      
                       }))
                       }else{
                        toast('Can not counter your stake',{
                          type:"error",
                          position:"top-center",
                          autoClose:5000,
                          hideProgressBar:true,
                    
                        })
                       }
               }} label='Counter'/>

<Chip onClick={async()=>{
                  getOdds([fixtureId, leagueId, date, season])
               }} label='Odds'/>
               </Stack>
               </Box>
               <Divider sx={{marginTop:1}}/>
             
              
            </Box>
            
              </>
            )
           })

           }

</Box>
<Dialog
        open={data.showConfirmCounter}
        fullWidth={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Counter Game
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          
<Box>
  
     <Typography sx={{fontSize:14, textAlign:'center'}}>NGN {formData.amount} will be deducted from your balance to counter game</Typography>
    
     {data.submitLoading && (
             <LinearProgress mt={1} />
           )}
     <Box textAlign="center" mt={2} fontWeight='bold'>
        <Button fullWidth disabled={data.submitLoading}  onClick={()=>{
        if(store.walletBalance.balance >= parseInt(formData.amount)){
          counterStake()
        }else{
          toast('Insufficient balance',{
            type:"error",
            position:"top-center",
            autoClose:5000,
            hideProgressBar:true,
      
          })
        }
          }} variant="contained" size="large">
     Counter
    </Button>
    </Box>

</Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          
          <Button  onClick={()=>{
            setData((prevState) => ({
              ...prevState,
              showConfirmCounter:false,
              }))
          }}>Cancel</Button>
          
         
        </DialogActions>
      </Dialog>

      
<Dialog
        open={data.openModalOdds}
        
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Odds
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          
<Box>
  
      {
      data.odds.filter(({ name })=> relevantOdds.includes(name)).map(({name, values})=>{
        return(
          <>
          <Typography sx={{fontWeight:'bold'}}>{name}</Typography>
{values.map(({odd, value})=> {
  return(<>
  <Stack direction="row" spacing={3}>
 <Stack direction="row" spacing={2}>
  
  <Typography fontSize='12px' sx={{fontWeight:'bold'}}>{value}</Typography> 
  <Typography fontSize='12px' sx={{fontWeight:'bold'}}>-</Typography>
  <Typography fontSize='12px' sx={{fontWeight:'bold'}}>{odd}</Typography>
  </Stack>
  </Stack>
  
  </>)
})}
     <Divider sx={{ marginTop:3, marginBottom:2}}/>    
          </>
        )
      })
  }
    


</Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          
          <Button  onClick={()=>{
            setData((prevState) => ({
              ...prevState,
              openModalOdds:false,
              }))
          }}>Close</Button>
          
         
        </DialogActions>
      </Dialog>
      <ToastContainer />
      </Box>
      </Container>
    </Page>
  );
}

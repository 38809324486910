// routes
import { useState } from 'react';
import Router from './routes';
import AppLoading from './pages/AppLoading'
import Footer from './pages/Footer'
// theme
import  { useEffect } from 'react';
import ThemeProvider from './theme';
// components

import React from 'react'
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import StoreContext from "../src/context/StoreContext";
import Store from "../src/store/index"
import { observer } from "mobx-react-lite";
import { useIdleTimer } from 'react-idle-timer'
import { useNavigate } from 'react-router-dom'
// ----------------------------------------------------------------------


export default function App() {
  const [isReady, setIsReady] = useState(false)
  const navigate = useNavigate();
  const onIdle = () => {
    
    localStorage.removeItem('user_token')
    window.location.href = '/login'
    
  }
  const idleTimer = useIdleTimer({onIdle})
  useEffect(() => {
    async function prepare() {
      try {
        if(localStorage.getItem('user_token')){
const getTimeVal = localStorage.getItem('expiry_time')
     if(new Date().getTime() < parseInt(getTimeVal)){
        Promise.all([
          Store.getUser(),
          Store.getUserToken(),
         Store.balance(),
         
         console.log('hello')
        ]).then(async () => {
          setIsReady(true)
      
        });
      }else{
        localStorage.removeItem('user_token')
        window.location.href = '/login'
      }
        }else{
          setIsReady(true)
        }
      } catch (e) {
        console.warn(e);
      } 
    }

    prepare();
  }, []);
  
  return (
    <>
    {isReady ? (
    <StoreContext.Provider value={Store}>
    <ThemeProvider>
      <ScrollToTop />
      <BaseOptionChartStyle />
      
      <Router />
     
    </ThemeProvider>
    </StoreContext.Provider>
    ):(
      <AppLoading/>
    )}
     <Footer />
 </> );
}

import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import { useContext } from 'react';
import Store from "../src/store/index"
//
import MyStakes from './pages/MyStakes';
import MyCounters from './pages/MyCounters';
import Transactions from './pages/Transactions';
import FundAccount from './pages/FundAccount';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Withdraw from './pages/Withdraw';
import DashboardApp from './pages/DashboardApp';
import StakeMarket from './pages/StakeMarket';
import StakeDetails from './pages/StakeDetails';
import AllStakes from './pages/AllStakes';
import ForgotPassword from './pages/ForgotPassword';
import Countries from './pages/Countries';
import Leagues from './pages/Leagues';
import PlaceStake from './pages/PlaceStake';
import Settings from './pages/Settings';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import HowTo from './pages/HowTo';
import StoreContext from "./store/index";
import { observer } from "mobx-react-lite";

// ----------------------------------------------------------------------

export default function Router() {
  const token = localStorage.getItem('user_token')
  
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'app', element:<DashboardApp /> },
        { path: 'fund-account', element:<FundAccount /> },
        { path: 'withdraw', element: <Withdraw /> },
        { path: 'my-stakes', element:<MyStakes /> },
        { path: 'transactions', element:<Transactions /> },
        { path: 'my-counters', element: <MyCounters /> },
        { path: 'place-stake', element: <PlaceStake /> },
        { path: 'stake-market', element: <StakeMarket /> },
        { path: 'all-stakes', element: <AllStakes /> },
        { path: 'get-countries', element: <Countries /> },
        { path: 'stake-details', element: <StakeDetails /> },
        { path: 'settings', element: <Settings /> },
        { path: 'get-leagues', element: <Leagues /> },

        

      ],
    },
    { 
    path: 'login' , 
    element: <Login /> 
  },
    {
    path: 'register', 
    element: <Register /> 
  },
  { 
    path: 'forgot-password', 
    element: <ForgotPassword /> 
  },

  { 
    path: 'how-to', 
    element: <HowTo /> 
  },
  { 
    path: 'terms', 
    element: <Terms /> 
  },
  { 
    path: 'privacy', 
    element: <Privacy /> 
  },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [

         { path: '/' , element: <Navigate to="/dashboard/app" /> },
         { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
    
  ]);
}

import * as Yup from 'yup';
import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, IconButton, InputAdornment, LinearProgress, Button, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import StoreContext from "../../../../src/context/StoreContext";

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const navigate = useNavigate();
  const store = useContext(StoreContext)
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName:"",
    email:"",
    password:"",
    verificationCode:''
  
   
  });
  const [data, setData] = useState({
    loading: false,
    verifyLoading:false
    
   
  });

  
    const sendCode = async () => {
      const verifyCode = Math.floor(Math.random() * 899999 + 100000).toString();
     
     
      console.log(verifyCode);
      localStorage.setItem('verifyCode', verifyCode)
      
      setData((prevState) => ({
        ...prevState,
  
        verifyLoading: true,
      }));
      axios
        .post(store.baseApiURL + "/send_email_verification_code", {
        verificationCode: verifyCode,
        email:formData.email})
        .then((response) => {
            console.log(response.data)
            setData((prevState) => ({
              ...prevState,
        
              verifyLoading: false,
            }));
          if (response.data === "code sent") {
            toast('Code sent',{
              type:"success",
              position:"top-center",
              autoClose:5000,
              hideProgressBar:true,
      
            })
          }
        })
        .catch((error) => {
          toast('Something went wrong, please try again',{
            type:"error",
            position:"top-center",
            autoClose:5000,
            hideProgressBar:true,
    
          })
          setData((prevState) => ({
            ...prevState,
  
            verifyLoading: false,
          }));
        });
    };
  
  
  const checkEmail =()=>{
    setData((prevState) => ({
      ...prevState,
      loading:true
       }))
    axios.post(`${store.baseApiURL}/check_email`, formData)
    .then(function (response) {
      
      if(response.data.msg === 'email exist'){
        setData((prevState) => ({
          ...prevState,
          loading:false
           }))
        toast('Email already exist',{
          type:"error",
          position:"top-center",
          autoClose:5000,
          hideProgressBar:true,
  
        })
      }else if(response.data.msg === "email does not exist"){
        onSubmit()
      }
      
      
    })
    .catch(function (error) {
      console.log(error);
      setData((prevState) => ({
        ...prevState,
        loading:false
         }))
      toast('An error occurred, please try again',{
        type:"error",
        position:"top-center",
        autoClose:5000,
        hideProgressBar:true,

      })
    });

  }
  const beforeSubmit = () =>{
    const upperCase = /[A-Z]/g;
    const lowerCase = /[a-z]/g;
    const number = /[0-9]/g;
    const passwordLength = formData.password.length >= 8;
    if(formData.verificationCode && formData.firstName && formData.lastName && formData.firstName && formData.email && formData.password ){
    
      if (
        formData.password.match(upperCase) &&
        formData.password.match(lowerCase) &&
        formData.password.match(number) &&
        passwordLength
      ) {
        if(formData.verificationCode === localStorage.getItem('verifyCode')){
      
      checkEmail()
        }else{
          toast('Verification code does not match',{
            type:"error",
            position:"top-center",
            autoClose:5000,
            hideProgressBar:true,
    
          })
        }
      }else{
        toast('Please enter a strong password',{
          type:"error",
          position:"top-center",
          autoClose:5000,
          hideProgressBar:true,
  
        })
      }
     
    }else{
      toast('All fields are required',{
        type:"error",
        position:"top-center",
        autoClose:5000,
        hideProgressBar:true,

      })
    }
  }


    const onSubmit = () => {
      setData((prevState) => ({
        ...prevState,
        loading:true
         }))

      axios.post(`${store.baseApiURL}/register`, formData)
      .then(function (response) {
        if(response.data.user === 'registered'){
        localStorage.setItem('user_token', response.data.token)
        const getimeValue = new Date().getTime() + (1000 * 60 * 60 * 1)
        localStorage.setItem('expiry_time', getimeValue)
        window.location.href = '/dashboard/app'
       // navigate('/dashboard/app', { replace: true });
        store.getUser()
      store.balance()

        }
        console.log(response);
        setData((prevState) => ({
          ...prevState,
          loading:false
           }))
      })
      .catch(function (error) {
        console.log(error);
        setData((prevState) => ({
          ...prevState,
          loading:false
           }))
        toast('An error occurred, please try again',{
          type:"error",
          position:"top-center",
          autoClose:5000,
          hideProgressBar:true,

        })
      });
    }





  

  return (
    
      
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            
            <TextField
              value={formData.firstName}
              fullWidth
              label="First name"
             
              onChange={(e)=> setFormData((prevState) => ({
                ...prevState,
                firstName:e.target.value
                 }))}
            />

            <TextField
              value={formData.lastName}
              fullWidth
              label="Last name"
              onChange={(e)=> setFormData((prevState) => ({
                ...prevState,
                lastName:e.target.value
                 }))}
            
            />
          </Stack>

          <TextField
            value={formData.email}
            fullWidth
            autoComplete="off"
            type="email"
            label="Email address"
            
            onChange={(e)=> setFormData((prevState) => ({
              ...prevState,
              email:e.target.value
               }))}

               
          />

<TextField
              
              fullWidth
              label="Verification code"
              type='number'
              onChange={(e)=> setFormData((prevState) => ({
                ...prevState,
               verificationCode:e.target.value
                 }))}
                 InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button edge="end" onClick={() =>{ 
                        if(formData.email){
                        sendCode()
                      }else{
                        toast('Enter email address',{
                          type:"error",
                          position:"top-center",
                          autoClose:5000,
                          hideProgressBar:true,
                
                        })
                      }}
                      }>
                        <Typography>Get code</Typography>
                      </Button>
                    </InputAdornment>
                  ),
                }}
            />
 {data.verifyLoading && (
             <LinearProgress />
           )}
          <TextField
            value={formData.password}
            fullWidth
            helperText="8 min characters, must include uppercase, lowercase and number"
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            onChange={(e)=> setFormData((prevState) => ({
              ...prevState,
              password:e.target.value
               }))}
           
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            
          />


           {data.loading && (
             <LinearProgress />
           )}
          <Button disabled={data.loading} fullWidth size="large" onClick={beforeSubmit} variant="contained" >
            Register
          </Button>
          <ToastContainer />
        </Stack>
      
   
  );
}

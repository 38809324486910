import { useState, useContext } from 'react';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  InputAdornment,
  Box,
   LinearProgress,
   Divider
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import StoreContext from "../../src/context/StoreContext";
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import axios from "axios";




export default function Transactions() {
  const store = useContext(StoreContext)
  const [data, setData] = useState({
    loading: false,
    transactionData:[],
     
   
  });



  useState(()=>{
    getTransactions()

  },[])





  function getTransactions(){
    setData((prevState) => ({
      ...prevState,
      loading:true,
     
       }))
    const token = localStorage.getItem('user_token')
    axios
    .get(`${store.baseApiURL}/get-transactions`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if(response.data){
        console.log(response.data)
        setData((prevState) => ({
          ...prevState,
          transactionData:response.data,
          
           }))
      
    }
      setData((prevState) => ({
        ...prevState,
        loading:false,
       
       
       
         }))

    }).catch((error) => {
      setData((prevState) => ({
    ...prevState,
    loading:false,
    
     }))
     
      toast('An error occurred, please try again',{
      type:"error",
      position:"top-center",
      autoClose:5000,
      hideProgressBar:true,

    })
      console.log(error)
    });

  } 


  return (
    <Page title="Transactions">
    
      <Container>
      <Box px={{sm:2, lg:25}}>
        <Box mb={5}>
          <Typography variant="h4" gutterBottom>
          Transactions
          </Typography>
         
        </Box>
      
{data.loading && (
             <LinearProgress mt={1}/>
           )}
       {data.transactionData.length === 0 && !data.loading && (
             <Typography mt={5} textAlign='center'>No Transactions</Typography>
           )}
       {data.transactionData.map(({amount, type, status, title, ref})=>{

         return (
           <>
           <Typography fontSize='14px' fontWeight='bold'>
             { title }
           </Typography>
           <Typography fontSize='12px' fontWeight='bold'>
          NGN { amount}
         </Typography>
         <Stack space={2} direction="row"  spacing={1}>
         <Typography fontSize='12px' >
           { type + ' -'} 
         </Typography>
         <Typography fontSize='12px' >
           { status + ' -'} 
         </Typography>
         <Typography fontSize='12px' >
           ref: { ref }
         </Typography>
         </Stack>
         <Divider sx={{marginTop:3}}/>
         </>
         )

       })

       }
        <ToastContainer />
        </Box>
      </Container>
    </Page>
  );
}
